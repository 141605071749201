import React, { useState } from 'react';
import { Box, IconButton, Tag, TagLabel, TagCloseButton, HStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

const ArticleTitleSelectionWrapper: React.FC = () => {
    const [labels, setLabels] = useState<string[]>([]);
    
    const addLabel = () => {
        const newLabel = `Article ${labels.length + 1}`;
        setLabels([...labels, newLabel]);
    };

    const deleteLabel = (index: number) => {
        setLabels(labels.filter((_, i) => i !== index));
    };

    return (
        <Box overflowX="auto" w="50%" border={'1px solid #ccc'} borderRadius={'10px'}>
            <HStack spacing={0} align="center">
                {labels.map((label, index) => (
                    <Tag key={index} size="lg" borderRadius={0} pl={5} pr={2} py={3} bg='white' border={'1px solid #ccc'}>
                        <TagLabel fontWeight={600}>{label}</TagLabel>
                        <TagCloseButton ml={3} onClick={() => deleteLabel(index)} />
                    </Tag>
                ))}
                <IconButton
                    p={5}
                    icon={<AddIcon />}
                    aria-label="Add Label"
                    onClick={addLabel}
                    variant='ghost'
                    size="sm"
                />
            </HStack>
        </Box>
    );
};

export default ArticleTitleSelectionWrapper;
