// Writer.tsx
import { Box, Flex, Text, Wrap, WrapItem, Heading, Modal, Image, useDisclosure, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast, Card, CardBody, Center, Container, Select, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import OutlineExtractor from '../components/OutlineExtractor/OutlineExtractor';
// import OutlineTemplateCard from '../components/OutlineTemplateCard/OutlineTempalteCard';
// import OutlineNodesDisplay from '../components/OutlineNodesDisplay/OutlineNodesDisplay';
import PaperCard from '../components/PaperCard/PaperCard';
import { useNavigate } from 'react-router-dom';
import {
    // createOutlineRequest, 
    // extractedOutlineResponse, 
    Outline
} from '../types/Writer/Outline';
import { BASE_URL, OutlineService } from '../services';
import { ClientError } from '../utils/clientError';
import { PaperService } from '../services/paperService';
import { useAuth0 } from "@auth0/auth0-react";
import { Paper } from '../types/Writer/Paper';
import { EditIcon } from '@chakra-ui/icons';
// import { BiPlus } from 'react-icons/bi';
import DraftEditor from '../components/PaperEditorComponents/DraftEditor';
// import NoDraftEditor from '../components/PaperEditorComponents/NoDraftEditor';
import SideNav from '../components/NavBar/SideNav';
import {
    PaperOutline,
    // PaperOutlineNode 
} from '../types/Writer/OutlineNode';
// import { MathJax } from 'better-react-mathjax';
// import { OutlineNode } from '../types/Writer/OutlineNode';
import { PiBookOpen, PiBookOpenText } from "react-icons/pi";
import { BiBlanket } from 'react-icons/bi';
import SearchBar from '../components/SearchBar/SearchBar';
import { BibContent } from '../types/Writer/BibContent';
import LangSelectV2 from '../components/NavBar/LangSelectV2';
import UserMenu from '../components/NavBar/Components/UserMenu';
import TemplateService from '../services/TemplateService';
import PreviewModalPanel from '../components/PreviewModalPanel/PreviewModalPanel';


const Writer: React.FC = () => {
    const { isOpen: _isOutlineOpen, onOpen: _onOutlineOpen, onClose: _onOutlineClose } = useDisclosure();
    const { isOpen: isPaperViewerOpen, onOpen: onPaperViewerOpen, onClose: onPaperViewerClose } = useDisclosure();
    const navigate = useNavigate();
    const [outlines, setOutlines] = useState<Outline[]>([]);
    const [papers, setPapers] = useState<Paper[]>([]);
    const [_selectedOutline, _setSelectedOutline] = useState<Outline>(null);
    const [selectedPaper, setSelectedPaper] = useState<Paper>(null);
    const toast = useToast();
    // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [_isExtractingOutline, _setIsExtractingOutline] = useState<boolean>(false);
    const { isOpen: isNewDocumentOpen, onOpen: onNewDocumentOpen, onClose: onNewDocumentClose } = useDisclosure();
    const [newDocumentStep, setNewDocumentStep] = useState<string>('init');
    const [paperTitle, setPaperTitle] = useState<string>('');
    const [paperTopic, setPaperTopic] = useState<string>('');
    const { user, isAuthenticated } = useAuth0();

    // const [testText, setTestText] = useState<string>('');

    const [sortOption, setSortOption] = useState<string>("modifiedDate");

    const[templateIndex, setTemplateIndex] = useState<number>(-1);
    const[templates, setTemplates] = useState<Array<Paper>>([]);
    const { isOpen: isOpenPreviewPanel, onOpen: onOpenPreviewPanel, onClose: onClosePreviewPanel } = useDisclosure();

    useEffect(() => {
        async function getOutlines() {
            try {
                const outlines = await OutlineService.getOutlines();
                setOutlines(outlines);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        getOutlines();
    }, [])

    useEffect(() => {
        async function getPapers() {
            try {
                const papers = await PaperService.getPapers();
                setPapers(papers);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        getPapers();
    }, [])
    
    useEffect(() => {
        TemplateService.getAllTemplates().then((templates)=>{
            setTemplates(templates)
        }).catch ((error)=>{
            new ClientError(error).toast();
        })
    }, [])

    // const templateCardOnClick = (outline: Outline) => {
    //     setSelectedOutline(outline);
    //     onOutlineOpen();
    // }

    const useTemplate = async (outline: PaperOutline, draft?: string, resources?: BibContent[]) => {
        console.log(
            outline,
            draft,
            resources
        )
        try {
            const newPaper = await PaperService.createPaper({
                name: paperTitle ? paperTitle : 'Untitled',
                authors: user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name,
                outline: outline,
                abstract: 'The abstract is a concise summary of your entire paper, providing a clear overview of your research problem, methodology, key findings, and conclusions. It typically consists of 150–250 words and should allow the reader to understand the essence of your research without needing to read the entire paper.',
                keywords: 'The keywords section lists the most important terms related to your research that help others find your paper through search engines or databases. Typically, you will include 4–6 keywords that are directly related to the core content of your research.',
                draft: draft ? draft : '',
                resources: resources ? resources : []
            });
            navigate(`/editor/${newPaper.id}`);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const continueEditing = (paper_id) => {
        console.log('continue to editing ', paper_id);
        navigate(`/editor/${paper_id}`);
    }

    const deletePaper = async (paperId) => {
        const originalPapers = papers;
        try {
            const newPapers = papers.filter(paper => paper.id !== paperId);
            setPapers(newPapers);
            await PaperService.deletePaper(paperId).then(
                res => {
                    console.log(res)
                }
            )
            toast({
                title: `Ppaer has been deleted.`,
                description: ``,
                status: "success",
                duration: 3000,
            })
        } catch (error) {
            new ClientError(error).toast();
            setPapers(originalPapers);
        }
    }

    // const deleteOutline = async (outlineId) => {
    //     const originalOutlines = outlines;

    //     try {
    //         const newOutlines = outlines.filter(outline => outline.id !== outlineId);
    //         setOutlines(newOutlines);

    //         await OutlineService.deleteOutline(outlineId);
    //     } catch (error) {
    //         new ClientError(error).toast();
    //         setOutlines(originalOutlines);
    //     }
    // }

    const viewPaper = async (paperId: string) => {
        const filteredPaper = papers.find(paper => paper.id === paperId);
        setSelectedPaper(filteredPaper);
        onPaperViewerOpen();
    }

    // const handleFilesChange = (files: File[]) => {
    //     setUploadedFiles(files);
    // };

    const newDocumentOnClick = () => {
        onNewDocumentOpen();
        setNewDocumentStep('init');
    }

    const onNewDocumentOnClose = () => {
        onNewDocumentClose();
        setPaperTitle('');
        setPaperTopic('');
    }

    const onDraftFinish = ({ draft, resources, outline }): void => {
        useTemplate(outline, draft, resources);
    }

    const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        setSortOption(selectedOption);

        const sortedPapers = [...papers].sort((a, b) => {
            if (selectedOption === "updatedAt") {
                return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            } else if (selectedOption === "createdAt") {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            } else if (selectedOption === "name") {
                return a.name.localeCompare(b.name);
            }
            return 0;
        });

        setPapers(sortedPapers);
    };

    return (
        <Flex flexDir={'row'} w={'full'} h={'full'} className='main-bg'>
            <SideNav />
            <Flex flexDir={'column'} w={'full'} h={'full'}>
                <Flex flexDir={'row'} w={'full'} h={'90px'} justify="flex-end" align='center' px={5}>
                    <Box mr={'30px'}>
                        <LangSelectV2 />
                    </Box>
                    {isAuthenticated && user && (
                        <UserMenu user={user} />
                    )}
                </Flex>
                <Container minW={'75%'}>
                    <SearchBar />
                    <Flex flexDir={'column'} h={'full'} p={5} overflow={'auto'} w={'full'}>
                        <Box bgColor={'rgba(255, 255, 255, 0.6)'} p={5} borderRadius={'25px'} w={'full'}>
                            <Heading size={'md'} mb={3}>Start a new document</Heading>
                            <Wrap mt={5} overflow={'auto'} w={'full'} >
                                <WrapItem>
                                    <HStack spacing='21px'>
                                        {templates.map((template, i)=>(
                                            <Card key={template.id} w={'150px'} h={'200px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #ccc' }} border={'1px solid #ccc'}>
                                                <CardBody onClick={()=>{setTemplateIndex(i);onOpenPreviewPanel()}}>
                                                    <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                                        <Image
                                                            w={'55px'}
                                                            objectFit='cover'
                                                            src={BASE_URL+template['snapshotS3Path']}
                                                            alt='template draft'
                                                        />
                                                    </Center>
                                                </CardBody>
                                                <Box mt={2} w={'full'} textAlign={'center'}>
                                                    <Text fontSize={14}>
                                                        {template.name}
                                                    </Text>
                                                </Box>
                                            </Card>
                                        ))}
                                        <Card w={'150px'} h={'200px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #ccc' }} border={'1px solid #ccc'}>
                                            <CardBody onClick={newDocumentOnClick}>
                                                <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                                    <Box>
                                                        <Image
                                                            w={'55px'}
                                                            objectFit='cover'
                                                            src='/doenba-ico-blue.png'
                                                            alt='Doenba'
                                                        />
                                                    </Box>
                                                </Center>
                                            </CardBody>
                                            <Box mt={2} w={'full'} textAlign={'center'}>
                                                <Text fontSize={14}>
                                                    Create
                                                </Text>
                                            </Box>
                                        </Card>
                                    </HStack>
                                </WrapItem>
                                {outlines.map(outline => (
                                    <WrapItem key={outline.id}>
                                        {/* <OutlineTemplateCard
                                        outline={outline}
                                        deleteOutline={() => deleteOutline(outline.id)}
                                        showDetail={() => templateCardOnClick(outline)}
                                        onUseTemplate={() => useTemplate(outline)}
                                    /> */}
                                    </WrapItem>
                                ))}
                            </Wrap>
                        </Box>

                        {papers.length > 0 &&
                            <Box mt={5} bgColor={'rgba(255, 255, 255, 0.6)'} p={5} borderRadius={'25px'} pb={12}>
                                <Flex flexDir={'row'} justifyContent={'space-between'}>
                                    <Heading size={'md'} pb={3}>Recent Documents ({papers.length})</Heading>
                                    <Box w="full" maxW="200px">
                                        <Select
                                            value={sortOption}
                                            onChange={handleSortingChange}
                                            variant="outline"
                                            borderColor="gray.300"
                                            _hover={{ borderColor: "gray.400" }}
                                        >
                                            <option value="updatedAt">Modified Date</option>
                                            <option value="createdAt">Opened Date</option>
                                            <option value="name">Title</option>
                                        </Select>
                                    </Box>
                                </Flex>

                                <Wrap mt={5} w={'full'} overflow={'auto'} h={'full'}>
                                    {papers.map(paper => (
                                        <WrapItem key={paper.id}>
                                            <PaperCard
                                                paper={paper}
                                                viewPaper={() => viewPaper(paper.id)}
                                                continueEditing={() => continueEditing(paper.id)}
                                                discardPaper={() => deletePaper(paper.id)}
                                            />
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </Box>
                        }
                    </Flex>
                </Container>
            </Flex>

            <Modal isOpen={isNewDocumentOpen} onClose={onNewDocumentOnClose} size={'lg'} closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent minW={'775px'} bg={'#fbfcff'}>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        {newDocumentStep === 'init' &&
                            <>
                                {/* <VStack spacing={4} align="start">
                                    <FormControl>
                                        <FormLabel>What is your article title?</FormLabel>
                                        <Input
                                            type="text"
                                            value={paperTitle}
                                            onChange={(e) => setPaperTitle(e.target.value)}
                                            placeholder="Enter your article title"
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>What is your writing topic?</FormLabel>
                                        <Textarea
                                            value={paperTopic}
                                            onChange={(e) => setPaperTopic(e.target.value)}
                                            placeholder="Enter your writing topic"
                                        />
                                    </FormControl>
                                </VStack> */}

                                <Flex flexDir={'row'} w={'full'} mb={12}>
                                    <Center borderRadius={5} boxShadow={'md'} mr={2} w={'50%'} cursor={'pointer'} h={'200px'} _hover={{ color: '#0057ec' }} onClick={() => setNewDocumentStep('hasWellStructuredDraft')} >
                                        <Flex flexDir={'column'} alignItems={'center'} >
                                            <Box mb={5}>
                                                <PiBookOpenText size={'50px'} />
                                            </Box>
                                            I have a well-structured draft
                                        </Flex>
                                    </Center>
                                    <Center borderRadius={5} boxShadow={'md'} mr={2} w={'50%'} cursor={'pointer'} h={'200px'} _hover={{ color: '#0057ec' }} onClick={() => setNewDocumentStep('hasPartialDraft')}>
                                        <Flex flexDir={'column'} alignItems={'center'}>
                                            <Box mb={5}>
                                                <PiBookOpen size={'50px'} />
                                            </Box>
                                            I have a part of draft
                                        </Flex>
                                    </Center>
                                    <Center borderRadius={5} boxShadow={'md'} w={'50%'} cursor={'pointer'} h={'200px'} _hover={{ color: '#0057ec' }} onClick={() => setNewDocumentStep('hasDraft')} >
                                        <Flex flexDir={'column'} alignItems={'center'} >
                                            <Box mb={5}>
                                                <BiBlanket size={'50px'} />
                                            </Box>
                                            I want to start with blank
                                        </Flex>
                                    </Center>
                                </Flex>
                            </>
                        }
                        {newDocumentStep === 'hasWellStructuredDraft' &&
                            <DraftEditor
                                onDraftFinish={onDraftFinish}
                                backToInit={() => setNewDocumentStep('init')}
                                paperTitle={paperTitle}
                                paperTopic={paperTopic}
                                setPaperTitle={setPaperTitle}
                                setPaperTopic={setPaperTopic}
                            />
                        }
                        {/* {newDocumentStep === 'hasPartialDraft' &&
                            <DraftEditor onDraftFinish={onDraftFinish} backToInit={() => setNewDocumentStep('init')} />
                        } */}
                        {/* {newDocumentStep === 'noDraft' &&
                            <NoDraftEditor onDraftFinish={() => useTemplate(outlines[0])} backToInit={() => setNewDocumentStep('init')} />
                        } */}
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button colorScheme='gray' onClick={onNewDocumentClose}>Discard</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>

            {selectedPaper && <Modal isOpen={isPaperViewerOpen} onClose={onPaperViewerClose} size={'full'}>
                <ModalOverlay />
                <ModalContent w={'90%'} h={'90vh'}>
                    <ModalHeader>{selectedPaper.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflow={'auto'}>
                        {JSON.stringify(selectedPaper.content)}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='yellow' size={'sm'} leftIcon={<EditIcon />} mr={3} onClick={() => continueEditing(selectedPaper.id)}>
                            Edit
                        </Button>
                        {/* <Button variant='ghost' size={'sm'} onClick={() => deletePaper(selectedPaper.id)}>Discard</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            }
            
            <Modal
                isOpen={isOpenPreviewPanel}
                onClose={onClosePreviewPanel}
                scrollBehavior="inside"
            >
                <ModalOverlay background={'whiteAlpha.200'} backdropFilter={'blur(2px)'}/>
                <ModalContent minW="672px" minHeight="880px" top="-30px">
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <PreviewModalPanel templateIndex={templateIndex} templates={templates}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default Writer;
