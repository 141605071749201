import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  useToast,
  Text,
  Center,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Paper } from "../../types/Writer/Paper";
import { ClientError } from "../../utils/clientError";
import DocumentService from "../../services/DocumentService";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

const PreviewModalPanel: FC<{
  templateIndex: number;
  templates: Array<Paper>;
}> = (props) => {
  const [htmlStr, setHtmlstr] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [templateIndex, setTemplateIndex] = useState<number>(
    props.templateIndex
  );
  const { t } = useTranslation();
  const toast = useToast();

  function updateTemplateByid(_templateIndex: number) {
    if (templateName == props.templates[_templateIndex].name) return;
    DocumentService.getTemplateById(props.templates[_templateIndex]?.id)
      .then((str) => {
        setHtmlstr(str);
        setTemplateName(props.templates[_templateIndex]?.name);
      })
      .catch((error) => {
        new ClientError(error).toast();
      });
  }
  useEffect(() => {
    updateTemplateByid(templateIndex);
  }, [templateIndex]);
  return (
    <Flex direction="column" gap="2">
      <Spacer />
      <Box>
        <Text fontSize="27px">{templateName}</Text>
      </Box>
      <Spacer />
      <Flex>
        <Center>
          <IconButton
            background="white"
            aria-label={"preview template"}
            icon={<IoIosArrowDropleft />}
            onClick={() => {
              if (templateIndex > 0) {
                setTemplateIndex(templateIndex - 1);
              } else {
                toast({
                  title: "Oops.",
                  description: t("editor-previewmodel-switchBtnTip"),
                  status: "info",
                  duration: 1000,
                });
              }
            }}
          />
        </Center>
        <Spacer />
        <Box
          width="512px"
          height="643px"
          borderRadius="10px"
          border="1px solid #eeeeee"
        >
          <iframe
            srcDoc={htmlStr}
            sandbox="allow-same-origin allow-scripts"
            style={{
              position: "relative",
              left: "-50%",
              top: "-50%",
              width: "200%",
              height: "200%",
              transform: "scale(0.5)",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Spacer />
        <Center>
          <IconButton
            background="white"
            aria-label={"next template"}
            icon={<IoIosArrowDropright />}
            onClick={() => {
              if (templateIndex < props.templates.length - 1) {
                setTemplateIndex(templateIndex + 1);
              } else {
                toast({
                  title: "Oops.",
                  description: t("editor-previewmodel-switchBtnTip"),
                  status: "info",
                  duration: 1000,
                });
              }
            }}
          />
        </Center>
      </Flex>
      <Spacer />
      <Center>
        <Text>{t("editor-previewmodel-selectlabeltip")}</Text>
      </Center>
      <Spacer />
      <Center>
        <Button
          bgColor="rgb(29, 138, 255)"
          color="white"
          onClick={() => {
            toast({
              title: "button click.",
              description: "select:" + props.templates[templateIndex].id,
              status: "success",
              duration: 1000,
            });
          }}
        >
          {t("editor-previewmodel-selectbtnlabel")}
        </Button>
      </Center>
    </Flex>
  );
};

export default PreviewModalPanel;
