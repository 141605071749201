import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Flex, Image, Heading, useToast, Textarea, Button,
    useDisclosure, Center,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Card,
    CardBody,
    CardHeader,
    Link as ChakraLink,
    CardFooter,
    VStack,
    Spinner,
    Text,
    ListItem,
    OrderedList,
    Checkbox,
    Badge,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaperService } from '../services/paperService';
import { ClientError } from '../utils/clientError';
import { PaperContent } from '../types/Writer/PaperContent';
import { PaperOutline, PaperOutlineNode } from '../types/Writer/OutlineNode';
import OutlineNodeTree from '../components/OutlineNodeTree/OutlineNodeTree';
// import wordCount from "word-count";
import { Paper } from '../types/Writer/Paper';
// import { WriterConversationService } from '../services/writerConversationService';
import { AiOutlineExport, AiOutlineThunderbolt } from 'react-icons/ai';
import ParagraphEditor from '../components/PaperEditor/PaperEditorNew';
import { RiExpandLeftFill, RiExpandRightFill } from "react-icons/ri";
// import { getOutlineNodeById, getParagraphContent } from '../utils/outlineNodeExtractor';
import {
    ReorganizedStructure,
    // ReorganizeSectionOptionsData
} from '../types/Writer/ReorganizeSectionResult';
import PaperEditorConversationPanel from '../components/PaperEditorConversationPanel.tsx/PaperEditorConversationPanel';
import { RephraseSentencesResult } from '../types/Writer/RephraseSentencesResult';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from '../components/NavBar/Components/UserMenu';
import { MdDeleteOutline, MdOutlineArticle } from 'react-icons/md';
import { GrArticle } from 'react-icons/gr';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';
import { IoIosInformationCircle, IoIosHelpCircle, IoMdArrowDropdownCircle, IoMdArrowDropupCircle, IoMdMore } from 'react-icons/io';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isPaperSaving, leftDrawerOpenStatus, paperSuggestions } from '../atoms/rootAtom';
import { FaListOl, FaPlus, FaQuoteLeft } from 'react-icons/fa';
import { IoChatboxEllipsesOutline, IoSwapHorizontal } from 'react-icons/io5';
import { BibContent } from '../types/Writer/BibContent';
import SuggestionWrapper from '../components/PaperEditor/SuggestionWrapper';
import { DeleteIcon } from '@chakra-ui/icons';
// import EditorNew from '../components/PaperEditor/EditorNew';

const WriterEditorWrapper: React.FC = () => {
    const [title, setTitle] = useState('');
    const [keywords, setKeywords] = useState<string>('');
    const [abstract, setAbstract] = useState<string>('');
    const [authors, setAuthors] = useState('');
    const [outline, setOutline] = useState<PaperOutline>(undefined);
    const [outlineNodes, setOutlineNodes] = useState<PaperOutlineNode[]>(undefined);
    const [paperContent, setPaperContent] = useState<PaperContent>({});
    const [draft, setDraft] = useState<string>('');
    const [resources, setResources] = useState<BibContent[]>([]);
    const [selectedNodeId, setSelectedNodeId] = useState<number | null>(1);
    // const navigate = useNavigate();
    const { paperId } = useParams();
    const toast = useToast();
    const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
    const titleInputRef = useRef<HTMLTextAreaElement>(null);
    const [isOutlineTreeOpen, setIsOutlineTreeOpen] = useState<boolean>(true);
    const [isChatWindowOpen, setIsChatWindowOpen] = useState<boolean>(true);
    const { isOpen: _isEditorOpen = true, onOpen: _onEditorOpen, onClose: _onEditorClose } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isOverviewModalOpen = true, onOpen: onOverviewModalOpen, onClose: onOverviewModalClose } = useDisclosure({ defaultIsOpen: false });
    const [_paper, setPaper] = useState<Paper>(null);
    const [rephraseData, setRephraseData] = useState<RephraseSentencesResult>()
    const { isOpen: isResultsModalOpen, onOpen: onResultsModalOpen, onClose: onResultsModalClose } = useDisclosure();
    const { user, isAuthenticated } = useAuth0();
    const [isSuggestionWindowOpen, setIsSuggestionWindowOpen] = useState<boolean>(false);

    const [isRephrasing, setIsRephrasing] = useState<boolean>(false);
    const [isReorganizing, setIsReorganizing] = useState<boolean>(false);

    const [_drawerOpenStatus, setDrawerOpenStatus] = useRecoilState(leftDrawerOpenStatus);
    const [paperIsSaving, setPaperIsSaving] = useRecoilState(isPaperSaving);

    const [referenceComponentOpenStatus, setReferenceComponentOpenStatus] = useState<boolean>(false);
    const suggestions = useRecoilValue(paperSuggestions);

    const [resourcesOnCollapse, setResourcesOnCollapse] = useState<boolean>(true);

    const [currentOpenTab, setCurrentOpenedTab] = useState<number>(-1);


    const navigate = useNavigate();

    useEffect(() => {
        async function fetchPaper() {
            try {
                const paper = await PaperService.getPaperById(paperId);
                setPaper(paper);
                setTitle(paper.name);
                setAuthors(paper.authors);
                setPaperContent(paper.content);
                setOutlineNodes(paper.outline.nodes);
                setOutline(paper?.outline);
                setKeywords(paper.keywords);
                setAbstract(paper.abstract);
                setDraft(paper.draft);
                setResources(paper.resources);
            } catch (error) {
                new ClientError(error).toast();
            }
        }
        if (paperId) {
            fetchPaper();
        }
    }, [paperId]);

    useEffect(() => {
        // console.log(getNodeContent(outline.nodes));
        console.log(selectedNodeId);
    }, [selectedNodeId])

    const toggleOutlineMenuOpenStatus = () => {
        setIsOutlineTreeOpen(!isOutlineTreeOpen);
    }

    const toggleChatWindowOpenStatus = () => {
        setIsChatWindowOpen(!isChatWindowOpen);
    }

    const updatePaperNodeContent = (nodeId, content) => {
        setPaperContent({
            ...paperContent,
            [nodeId]: content
        })
    }

    const savePaper = () => {
        setPaperIsSaving(true);
        PaperService.updatePaper(paperId, {
            name: title,
            authors: authors,
            content: paperContent,
            abstract: abstract,
            keywords: keywords,
            draft: draft,
            resources: resources,
            outline: outline
        }).then(_res => {
            toast({
                title: "Success",
                description: `Paper has been saved.`,
                status: "success",
                duration: 1000,
            })
            setPaperIsSaving(false);
        }).catch(_err => {
            toast({
                title: "File Processing Failed",
                description: `Paper saving failed. Please try again later.`,
                status: "error",
            });
            setPaperIsSaving(false);
        })
    }

    const handleReorganizeSectionClick = async () => {
        currentOpenTab === 2 ? setCurrentOpenedTab(-1) : setCurrentOpenedTab(2);
        setIsReorganizing(true);
        try {
            const result = await PaperService.reorganizeSection(outline, paperContent);
            // setOutline(result.outline); // MAY NEED TO UPDATE STATE.. BE CAREFUL HERE!!!

            // Set the options and open the modal
            // setReorganizeSectionOptionsData(result.reorganizeSectionOptionsData);
            console.log({ reorganizeSectionResponse: result })

            // In the modal, present the 3 options. User selects one, and we call:
            handleSectionOptionClick(
                result.outline,
                result.reorganizeSectionOptionsData.reorganized_structure_version_1.reorganized_structure
            );
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setIsReorganizing(false);
        }
    }

    const handleSectionOptionClick = async (outline: PaperOutline, reorganizedStructure: ReorganizedStructure[]) => {
        // OUTLINE NEEDS TO BE THE SAME OUTLINE RETURNED FROM THE REORGANIZE SECTION CALL
        try {
            const result = await PaperService.rewriteSection(outline, reorganizedStructure);

            console.log({ rewriteSectionResponse: result });

            setOutline(result.outline);
            setPaperContent(result.paperContent);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const handleRephraseSentencesClick = async (sentences: string = null) => {
        currentOpenTab === 0 ? setCurrentOpenedTab(-1) : setCurrentOpenedTab(0);

        setIsRephrasing(true);
        if (!sentences) {
            // sentences = Object.values(paperContent).join('\n');
            getNodeContent(outline.nodes).children.forEach(node => {
                sentences += node.content;
            })
            console.log(sentences)
        }
        try {
            const result = await PaperService.rephraseSentences(sentences);
            setRephraseData(result)
            onResultsModalOpen();
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setIsRephrasing(false);
        }
    }

    const handleSuggestionSentenceClick = async (sentences: string = null) => {
        currentOpenTab === 1 ? setCurrentOpenedTab(-1) : setCurrentOpenedTab(1);
        setIsSuggestionWindowOpen(!isSuggestionWindowOpen);
        if (!sentences) {
            getNodeContent(outline.nodes).children.forEach(node => {
                sentences += node.content;
            })
            console.log(sentences)
        }
    }

    const getNodeContent = (outlineNode: PaperOutlineNode[]) => {
        return outlineNode.find(node => node.id === selectedNodeId)
    }

    // const applySuggestedContent = (suggestion_id: string) => {
    //     setIsSuggestionWindowOpen(false);
    // }

    const logoOnClick = () => {
        navigate('/editor')
    }

    const overviewOnClick = () => {
        onOverviewModalOpen();
    }

    const rephraseDataOnClick = (versionIndex: number) => {

        const deepCopiedPaperContent = structuredClone(paperContent);

        getNodeContent(outline.nodes).children.forEach(node => {
            console.log(node, versionIndex)
        })

  
        console.log(deepCopiedPaperContent[`${String(selectedNodeId)}`])
        
    }

    const resourceOnRemove = (resourceIndex: number) => {
        const updatedResources = resources.filter((_, index) => index !== resourceIndex);
        const updatedPaperContent = { ...paperContent };
        delete updatedPaperContent[resourceIndex]; 
        setResources(updatedResources);
        setPaperContent(updatedPaperContent);
        savePaper();
    }

    return (
        <Flex flexDir={'row'} w={'full'} h={'full'}>
            <Flex bg={'gray.100'} h={'100%'} flexDir={'column'} w={isOutlineTreeOpen ? '100px' : '350px'} justifyContent={'space-between'} boxShadow={'md'} position={'relative'} transition={'all 0.5s ease'}>
                <Flex h={'full'} flexDir={'column'} justifyContent={'space-between'}>
                    <Flex flexDir={'column'}>
                        <Center p={5}>
                            <Box w={isOutlineTreeOpen ? '30px' : '150px'} h={'50px'} onClick={logoOnClick} cursor={'pointer'}>
                                <Image
                                    objectFit='cover'
                                    src={isOutlineTreeOpen ? '/doenba-ico-blue.png' : '/doenba-logo-blue.png'}
                                    alt='Doenba'
                                />
                            </Box>
                        </Center>
                        <Flex flexDir={'column'} px={5} py={2} borderBottom={'1px solid #ccc'}>
                            {!isOutlineTreeOpen &&
                                <Button w={'full'}
                                    leftIcon={<MdOutlineArticle />}
                                    rightIcon={referenceComponentOpenStatus ? <IoMdArrowDropupCircle /> : <IoMdArrowDropdownCircle />}
                                    onClick={() => setReferenceComponentOpenStatus(!referenceComponentOpenStatus)}>
                                    Reference Article
                                </Button>
                            }
                            {isOutlineTreeOpen &&
                                <IconButton w={'full'} icon={<MdOutlineArticle />} aria-label={'Reference article'} transition={'all 0.5s ease'} />
                            }
                            {!isOutlineTreeOpen && referenceComponentOpenStatus &&
                                <Box px={2} py={3} bg={'gray.300'} borderRadius={'0 0 5px 5px'} transition={'all 0.5s ease'}>
                                    <Flex flexDir={'row'} mb={3} justifyContent={'space-between'}>
                                        <Checkbox defaultChecked>Structure</Checkbox>
                                        <Box>
                                            <IconButton mr={2} size={'xs'} aria-label='replace' icon={<IoSwapHorizontal />} />
                                            <IconButton size={'xs'} aria-label='remove' icon={<MdDeleteOutline />} />
                                        </Box>
                                    </Flex>
                                    <Flex flexDir={'row'} justifyContent={'space-between'}>
                                        <Checkbox defaultChecked>Tone</Checkbox>
                                        <Box>
                                            <IconButton mr={2} size={'xs'} aria-label='replace' icon={<IoSwapHorizontal />} />
                                            <IconButton size={'xs'} aria-label='remove' icon={<MdDeleteOutline />} />
                                        </Box>
                                    </Flex>
                                </Box>
                            }
                        </Flex>
                        <Box mt={5} overflow={'auto'} >
                            {outline &&
                                <OutlineNodeTree
                                    isOutlineTreeOpen={isOutlineTreeOpen}
                                    selectedNodeId={selectedNodeId}
                                    outline={outline}
                                    setOutline={setOutline}
                                    setSelectedNodeId={setSelectedNodeId}
                                />
                            }
                        </Box>
                    </Flex>
                    <Flex flexDir={'column'} p={5} pb={12} justifyContent={'space-between'} h={'30%'}>
                        <Flex flexDir={'column'}>
                            {!isOutlineTreeOpen &&
                                <Button mb={2} w={'full'} leftIcon={<GrArticle />} onClick={overviewOnClick}>Overview</Button>
                            }
                            {isOutlineTreeOpen &&
                                <IconButton mb={2} w={'full'} icon={<GrArticle />} aria-label={'Overview'} />
                            }
                            {!isOutlineTreeOpen &&
                                <Button mb={2} w={'full'} leftIcon={<TbAdjustmentsHorizontal />}>Style</Button>
                            }
                            {isOutlineTreeOpen &&
                                <IconButton mb={2} w={'full'} icon={<TbAdjustmentsHorizontal />} aria-label={'Style'} />
                            }
                            {!isOutlineTreeOpen &&
                                <Button mb={2} w={'full'} leftIcon={<AiOutlineExport />}>Export</Button>
                            }
                            {isOutlineTreeOpen &&
                                <IconButton mb={2} w={'full'} icon={<AiOutlineExport />} aria-label={'Export'} />
                            }
                        </Flex>

                        <VStack py={12}>
                            {!isOutlineTreeOpen &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">Tutorials</ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">Helps</ChakraLink>
                                </>
                            }
                            {isOutlineTreeOpen &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosInformationCircle />
                                    </ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosHelpCircle />
                                    </ChakraLink>
                                </>
                            }
                        </VStack>
                    </Flex>
                </Flex>
                <Box position={'absolute'} top={'110px'} right={'-10px'} bg={'white'} >
                    <IconButton size={'xs'} variant={'outline'} icon={isOutlineTreeOpen ? <RiExpandRightFill /> : <RiExpandLeftFill />} aria-label='Collapse' onClick={toggleOutlineMenuOpenStatus} />
                </Box>
            </Flex>
            <Box display={'flex'} flexDir={'column'} flex={'1 auto'} w={`calc(100% - ${isOutlineTreeOpen ? '600px' : '850px'})`} h={'100vh'} pb={5}>
                <Box w={'full'} textAlign={'left'} my={5} h={'50px'} pl={10}>
                    {isTitleEditing ? (
                        <Textarea
                            ref={titleInputRef}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={() => {
                                setIsTitleEditing(false);
                                savePaper();
                            }}
                            autoFocus
                            style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: 36, fontWeight: 600 }}
                            sx={{
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                            }}
                            _focus={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                            _hover={{
                                border: 'none',
                            }}
                        />
                    ) : (
                        <Heading onClick={() => setIsTitleEditing(true)}
                            style={{ fontFamily: '"Times New Roman", Times, serif' }}
                        >{title !== '' ? title : 'Untitled'}</Heading>
                    )}
                </Box>

                <Flex flex={'1 auto'} flexDir={'row'} w={'full'} h={'calc(100% - 250px)'}>
                    <Box flex={'1'} w={!isSuggestionWindowOpen ? '90%' : '50%'} overflow={'auto'}>
                        {outline && outline.nodes &&
                            <ParagraphEditor
                                outline={outline}
                                nodeContent={getNodeContent(outline.nodes)}
                                updatePaperNodeContent={(content) => updatePaperNodeContent(selectedNodeId, content)}
                                handleInputFocus={() => setSelectedNodeId(selectedNodeId)}
                                savePaper={savePaper}
                                // selectedContextRephrase={selectedContextRephrase}
                            />
                            // <EditorNew data={getNodeContent(outline.nodes)}/>
                        }
                    </Box>

                    {isSuggestionWindowOpen &&
                        <SuggestionWrapper sectionData={getNodeContent(outline.nodes)} />
                    }
                </Flex>

                <Center w={'full'}>
                    <Flex flexDir={'row'} border={'1px solid #ccc'} borderRadius={'lg'} bg={'gray.100'} h={'50px'} justifyContent={'space-between'} alignItems={'center'} boxShadow={'md'}>
                        <Flex flexDir={'row'} alignItems={'center'} px={5} mr={2}>
                            <Box mr={2}>
                                <AiOutlineThunderbolt color='#009cf0' />
                            </Box>
                            AI Tools
                        </Flex>
                        <Button mr={2} size={'sm'} isLoading={isRephrasing} onClick={() => handleRephraseSentencesClick()} variant={currentOpenTab === 0 ? 'outline' : 'ghost'}>Rephrase</Button>
                        <Button mr={2} size={'sm'} onClick={() => handleSuggestionSentenceClick()} variant={currentOpenTab === 1 ? 'outline' : 'ghost'}>Suggestions {suggestions.review_aspect?.length && suggestions.review_aspect?.length > 0 ? `(${suggestions.review_aspect?.length})` : ''}</Button>
                        <Button mr={2} size={'sm'} isLoading={isReorganizing} onClick={handleReorganizeSectionClick} variant={currentOpenTab === 2 ? 'outline' : 'ghost'}>Reorganize</Button>
                    </Flex>
                </Center>
                <Flex flexDir={'row'} justifyContent={'flex-end'}>
                    <Flex flexDir={'row'} alignItems={'center'}>
                        {paperIsSaving &&
                            <Spinner size="xs" mr={2} color='green' />
                        }
                        <Text color={'gray'}> Saved</Text>
                    </Flex>
                </Flex>
            </Box>
            <Flex flexDir={'column'} w={isChatWindowOpen ? '450px' : '100px'} h={'full'} position={'relative'} overflow={'hidden'} p={5} transition={'all 0.5s ease'}>
                <Flex flexDir={'row'} justifyContent={'flex-end'}>
                    <Box pl='2' mr='2'>
                        {isAuthenticated && user && (
                            <UserMenu user={user} />
                        )}
                    </Box>
                </Flex>
                {isChatWindowOpen &&
                    <>
                        <Card mt={2} mb={2} bg={'#f9f9f9'} border={'1px solid #ccc'} borderRadius={'25px'} h={resourcesOnCollapse ? '400px' : '80px'} transition={'all 0.5s ease'}>
                            <CardHeader>
                                <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading size='md'>Resources Library</Heading>
                                    <IconButton variant={'ghost'} aria-label='collapse' size={'md'} onClick={() => setResourcesOnCollapse(!resourcesOnCollapse)} icon={resourcesOnCollapse ? <IoMdArrowDropupCircle /> : <IoMdArrowDropdownCircle />} />
                                </Flex>
                            </CardHeader>
                            {resourcesOnCollapse &&
                                <>
                                    <CardBody overflow={'auto'} maxH={'35vh'} >
                                        <OrderedList spacing={3}>
                                            {resources.map((resource, index) => (
                                                <ListItem key={resource.json_info.doi + index}>
                                                    <Flex flexDir={'row'}>
                                                        <Box w={'full'}>
                                                            <Box><Text as='b'>{resource.json_info.title} - {resource.json_info.year}</Text></Box>
                                                            <Box><Text color={'gray.400'}>{resource.json_info.authors}</Text></Box>
                                                        </Box>
                                                        <Flex flexDir={'column'} justifyContent={'space-between'} alignItems={'flex-end'}>
                                                            <Box>
                                                                <Badge variant='outline' colorScheme='brand' borderRadius={'15PX'}>
                                                                    0
                                                                </Badge>
                                                            </Box>
                                                            <Box>
                                                                <Menu>
                                                                    <MenuButton size={'xs'}
                                                                        as={IconButton}
                                                                        aria-label='Options'
                                                                        icon={<IoMdMore />}
                                                                        variant='outline'
                                                                    />
                                                                    <MenuList>
                                                                        <MenuItem icon={<DeleteIcon />} onClick={() => resourceOnRemove(index)}>
                                                                            Remove
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Menu>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                </ListItem>
                                            ))}
                                        </OrderedList>
                                    </CardBody>
                                    <CardFooter>
                                        <Flex w={'full'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Box>
                                                <Button colorScheme='brand' size={'sm'} leftIcon={<FaQuoteLeft />}>Cite</Button>
                                            </Box>
                                            <Box>
                                                <Button colorScheme='brand' size={'sm'} leftIcon={<FaPlus />}>Add</Button>
                                            </Box>
                                        </Flex>
                                    </CardFooter>
                                </>
                            }
                        </Card>
                        <Card bg={'#f9f9f9'} border={'1px solid #ccc'} borderRadius={'25px'} h={`calc(100% - ${resourcesOnCollapse ? '460px' : '140px'})`} transition={'all 0.5s ease'}>
                            <CardBody h={'full'}>
                                <PaperEditorConversationPanel
                                    paperId={paperId}
                                    selectedNodeId={selectedNodeId}
                                    setSelectedNodeId={setSelectedNodeId}
                                    outlineNodes={outlineNodes}
                                />
                            </CardBody>
                        </Card>
                    </>
                }
                {!isChatWindowOpen &&
                    <>
                        <IconButton mt={5} mb={2} icon={<FaListOl />} aria-label='References' onClick={() => setIsChatWindowOpen(true)} />
                        <IconButton mb={2} icon={<IoChatboxEllipsesOutline />} aria-label='Chat' onClick={() => setIsChatWindowOpen(true)} />
                    </>
                }

                <Box position={'absolute'} top={'110px'} left={'-10px'} bg={'white'}>
                    <IconButton size={'xs'} variant={'outline'} icon={isChatWindowOpen ? <RiExpandRightFill /> : <RiExpandLeftFill />} aria-label='Collapse' onClick={toggleChatWindowOpenStatus} />
                </Box>
            </Flex>

            <Modal isOpen={isOverviewModalOpen} onClose={onOverviewModalClose} size={'full'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Overview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDir={'row'} h={'80vh'} overflow={'auto'}>
                            {rephraseData && rephraseData.rephraseVersions.map(version =>
                                <Flex flexDir={'column'} p={2}>
                                    <Box>{version}</Box>
                                    <Box mt={5}>
                                        <Button w={'full'} size={'sm'} colorScheme='brand'>Select</Button>
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isResultsModalOpen} onClose={onResultsModalClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent bg={'gray.100'}>
                    <ModalHeader>Recommended Structure</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDir={'row'} pb={12}>
                            {rephraseData && rephraseData.rephraseVersions.map((version, index) =>
                                <Flex
                                    flexDir={'column'}
                                    p={3}
                                    bg={'white'}
                                    mx={1}
                                    borderRadius={'15px'}
                                    border={'1px solid #ccc'}
                                    cursor={'pointer'}
                                    _hover={{ bg: 'gray.200' }}
                                    onClick={() => rephraseDataOnClick(index)}>
                                    <Box borderRadius={'5px'} bg={'#009cf0'} w={'52px'} p={2}>
                                        <Heading size={'xs'} color={'white'}>Draft {index + 1}</Heading>
                                    </Box>
                                    <Box h={'500px'} overflow={'auto'} mt={5}>{version}</Box>
                                </Flex>
                            )}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default WriterEditorWrapper;

