export interface OutlineNode {
	id: number;
	name: string;
	children: Omit<OutlineNode, 'children'>[];
}

export enum OutlineNodeType {
	Section = "section",
	Paragraph = "paragraph"
}

export interface PaperOutlineNode {
	id: number;
	type: OutlineNodeType;

	// For Section only
	name?: string;
	children?: PaperOutlineNode[];
	level?: number;

	// For Paragraph only
	content?: string;
}

export interface PaperOutline {
	nodes: PaperOutlineNode[];
	nextId: number;
}