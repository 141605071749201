import { atom, selector } from 'recoil';
import { FileDetails, SelectedFileDetails } from "../types/FileDetails";
import { FileProcessingStatus, ProcessingStatus } from '../types/FileProcessingStatus';
import { VideoType } from '../components/VideoCarousel/video.utils';
import { DirectoryWithChildren } from '../types/Directory';
import { Outline } from '../types/Writer/Outline';
import { MyQuestion } from '../types/QuestionsList';
import { PaperSuggestions } from '../types/Writer/PaperNodeSuggestion';


export const selectedArticlesState = atom({
    key: "selectedArticlesState",
    default: [] as SelectedFileDetails[]
})

export const currentOpenedArticleIdState = atom({
    key: "currentOpenedArticleIdState",
    default: '' as string
})

export const appLoadingState = atom({
    key: "appLoadingState",
    default: false as boolean // App.tsx will set to false after interceptor is done
})

export const appLoadingText = atom({
    key: "appLoadingText",
    default: 'Loading' as string
})

/**
 * An array of file processing states
 * {
 *  fileId1: FileProcessingStatus,
 *  fileId2: FileProcessingStatus,
 *  fileId3: FileProcessingStatus,
 * }
 */
const fileProcessingState = atom({
    key: 'fileProcessingState',
    default: new Map<string, FileProcessingStatus>(),
})

export const fileProcessingSelector = selector({
    key: 'fileProcessingSelector',
    get: ({ get }) => {
        const fileProcessingStateAtom = get(fileProcessingState);

        // Fallback to localStorage if map is empty
        if (fileProcessingStateAtom.size === 0) {
            const fileProcessingState = localStorage.getItem('fileProcessingState');
            if (!fileProcessingState) {
                return new Map<string, FileProcessingStatus>();
            }

            const parsedState: Map<string, FileProcessingStatus> = new Map(JSON.parse(fileProcessingState));
            if (typeof Array.from(parsedState.values())[0] === 'number') {
                return new Map<string, FileProcessingStatus>();
            }

            return parsedState;
        } else {
            return fileProcessingStateAtom;
        }
    },
    set: ({ set }, newValue: Map<string, FileProcessingStatus>) => {
        // for (const [fileId, expiresTime] of newValue) {
        //     if (expiresTime < Date.now()) {
        //         newValue.delete(fileId);
        //     }
        // }

        const uncompleteEntries = Array.from(newValue.entries()).filter(([, value]) => value.processingStatus === ProcessingStatus.PENDING);

        localStorage.setItem('fileProcessingState', JSON.stringify(uncompleteEntries));
        set(fileProcessingState, newValue);
    },
});

export const searchResultProcessingState = atom({
    key: 'searchResultProcessingState',
    default: false,
})

export const uploadingFile = atom({
    key: 'uploadingFile',
    default: null as FileDetails | null
})

export const popUpShowStatus = atom({
    key: 'popUpShowStatus',
    default: false as boolean
})

export const myDriveFilesState = atom({
    key: 'myDriveFilesState',
    default: [] as FileDetails[]
});

/**
 * This atom will store the nested directory structure of the user's drive, as it is loaded.
 * This will basically only be populated as the user navigates through the directories in
 * their drive.
 */
export const myDriveDirectoriesState = atom({
    key: 'myDriveDirectoriesState',
    default: new Map<number | null, DirectoryWithChildren>(),
});

export const myReadingListState = atom({
    key: 'myReadingListState',
    default: [] as FileDetails[]
});

/**
 * This selector is used to update potentially old file data in the global file state atoms:
 * - myDriveFilesState
 * - myReadingListState
 * - selectedArticlesState
 * - uploadingFile
 * It is write-only and should not be used to read data.
 *
 */
export const filesSetter = selector({
    key: 'filesSetter',
    get: () => {
        throw new Error('filesSetter selector is write-only');
    },
    set: ({ get, set }, newFiles: FileDetails | FileDetails[]) => {
        const newFilesArray = Array.isArray(newFiles) ? newFiles : [newFiles];

        const myDriveFiles = get(myDriveFilesState);
        const myReadingList = get(myReadingListState);
        const selectedArticles = get(selectedArticlesState);

        const newDriveFiles = myDriveFiles.map((file) => {
            const newFile = newFilesArray.find((newFile) => newFile.id === file.id);
            return newFile ? newFile : file;
        });

        const newReadingList = myReadingList.map((file) => {
            const newFile = newFilesArray.find((newFile) => newFile.id === file.id);
            return newFile ? newFile : file;
        });

        const newSelectedArticles = selectedArticles.map((selectedArticle) => {
            const newFile = newFilesArray.find((newFile) => newFile.id === selectedArticle.id);
            return newFile ? newFile : selectedArticle;
        });

        const currentUploadingFile = get(uploadingFile);
        const newUploadingFile = newFilesArray.find((file) => file.id === currentUploadingFile?.id);
        if (newUploadingFile) {
            set(uploadingFile, newUploadingFile);
        }

        set(myDriveFilesState, newDriveFiles);
        set(myReadingListState, newReadingList);
        set(selectedArticlesState, newSelectedArticles);
    }
});

export const articlePreviewModalOpenStatus = atom({
    key: 'articlePreviewModalOpenStatus',
    default: false as boolean
})

export const holdingForUploadingFiles = atom({
    key: 'holdingForUploadingFiles',
    default: [] as File[]
})

export const duplicatedPendingArticleId = atom({
    key: 'duplicatedPendingArticleId',
    default: '' as string
})

export const isQuickUploader = atom({
    key: 'isQuickUploader',
    default: false as boolean
})

export const globalVideoPlayerOpenStatus = atom({
    key: 'globalVideoPlayerOpenStatus',
    default: false as boolean
})

export const videoResource = atom({
    key: 'videoResource',
    default: { title: '', source: '' } as VideoType
})

export const leftDrawerOpenStatus = atom({
    key: 'leftDrawerOpenStatus',
    default: false as boolean
})

export const currentDirectoryIdState = atom({
    key: 'currentDirectoryId',
    default: null as number | null
});

export const følderPath = atom({
    key: 'folderPath',
    default: [] as string[]
})

export const pendingFileIdForCutting = atom({
    key: 'pendingFileIdForCutting',
    default: {
        id: '' as string,
        name: '' as string
    }
})

export const quoteSentenceIndexesState = atom({
    key: 'quoteSentenceIndexes',
    default: [] as number[]
})

export const selectedMessageBubbleId = atom({
    key: 'selectedMessageBubbleId',
    default: '' as string
})

export const selectedPaperOutline = atom({
    key: 'selectedPaperOutline',
    default: {} as Outline
})

export const allMyQuestionsList = atom({
    key: 'allMyQuestionsList',
    default: [] as MyQuestion[]
})

export const isPaperSaving = atom({
    key: 'isPaperSaving',
    default: false
})

export const isNewUI = atom({
    key: 'isNewUI',
    default: true
})

export const paperSuggestions = atom({
    key: 'paperSuggestions',
    default: {} as PaperSuggestions
})
