import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Flex, Text, Select, Image, IconButton } from "@chakra-ui/react"
import { useEffect, useState } from "react";

import { PaperService } from "../../services/paperService";
import { PaperOutlineNode } from "../../types/Writer/OutlineNode";
import { useRecoilState } from "recoil";
import { paperSuggestions } from "../../atoms/rootAtom";
import { PaperSuggestions } from "../../types/Writer/PaperNodeSuggestion";
import { IoMdArrowDropdownCircle } from "react-icons/io";

type SuggestionWrapperProps = {
    sectionData: PaperOutlineNode;
}

const SuggestionWrapper = ({ sectionData }: SuggestionWrapperProps) => {
    const [accomplishedSuggestionCount, setAccomplishedSuggestionCount] = useState<number>(0);
    const [suggestionLevel, setSuggestionLevel] = useState<string>('phd');
    const [suggestions, setSuggestions] = useRecoilState<PaperSuggestions>(paperSuggestions);
    const [isReviewing, setIsReviewing] = useState<boolean>(false);
    const [isRescoring, setIsRescoring] = useState<boolean>(false);

    useEffect(() => {
        let accomplishedSuggestionsCount = 0;
        suggestions.review_aspect?.forEach(review => {
            if(review.score == 3) {
                accomplishedSuggestionsCount += 1;
            }
        })
        setAccomplishedSuggestionCount(accomplishedSuggestionCount);
    }, [suggestions])

    const reviewSuggestions = () => {
        setIsReviewing(true);
        PaperService.suggestion(sectionData, suggestionLevel).then(
            (suggestion) => {
                setSuggestions(suggestion);
                setIsReviewing(false);
                PaperService.updatePaper
            }
        )
    }

    const suggestionOnApply = (index: number) => {
        console.log(index);
        setIsRescoring(true);
        PaperService.sectionSuggestion({
            section_data: sectionData,
            suggestion_data: suggestions.review_aspect[index]
        }).then(res => {
            const deepCopiedSuggestions = structuredClone(suggestions);
            deepCopiedSuggestions.review_aspect[index] = res;
            setSuggestions(deepCopiedSuggestions); 
            setIsRescoring(false);
        }).catch((err) => {
            console.log(err);
            setIsRescoring(false);
        })
    }

    const suggestionOnIgnore = (index: number) => {
        const deepCopiedSuggestions = structuredClone(suggestions);
        deepCopiedSuggestions.section_review_aspect.splice(index, 1);
        deepCopiedSuggestions.review_aspect.splice(index, 1);
        setSuggestions(deepCopiedSuggestions);
    }

    return (
        <Flex flexDir='column' mt={12} w={'350px'}>
            <Flex flexDir={'row'} alignItems={'center'} mb={3}>
                <Select mr={2} size={'sm'} value={suggestionLevel} onChange={(e) => setSuggestionLevel(e.target.value)}>
                    <option value='phd'>PhD</option>
                    <option value='high_school'>High School</option>
                    <option value='bacheloar'>Bachelor</option>
                    <option value='master'>Master</option>
                    <option value='researcher'>Researcher</option>
                </Select>
                <Button size={'sm'} colorScheme={'brand'} onClick={reviewSuggestions} px={5} isLoading={isReviewing}>Review</Button>
            </Flex>
            <Center mb={3} py={4} bg={'gray.100'} boxShadow={'md'} borderRadius={'15px'} color={'gray.400'}>
                {accomplishedSuggestionCount} / {suggestions?.section_review_aspect?.length} Suggestions
            </Center>
            <Box overflow={'auto'} maxH={'80vh'}>
                {suggestions?.review_aspect?.map((section, index) => (
                    <Card key={index} w={'350px'} bg={'gray.100'} mb={3} borderRadius={'15px'} boxShadow={'md'} >
                        <CardHeader>
                            <Flex flexDir={'row'} justifyContent={'space-between'}>
                                <Box w={'80px'}>
                                    <Image
                                        objectFit='cover'
                                        src={'/doenba-logo-blue.png'}
                                        alt='Doenba'
                                    />
                                </Box>
                                <Box>
                                    <IconButton
                                        aria-label='collapse'
                                        icon={<IoMdArrowDropdownCircle />} />
                                </Box>
                            </Flex>
                        </CardHeader>
                            <>
                                <CardBody py={0}>
                                    <Box>
                                        <Text as='b'>{section.review_aspect}</Text>: {section.review}
                                    </Box>
                                    <Box mt={3}>
                                        <Text as='b'>Suggestions: </Text>{section.suggestion}
                                    </Box>
                                    <Box mt={3}>
                                        <Text as='b'>Score: </Text>{section.score}
                                    </Box>
                                </CardBody>
                                <CardFooter>
                                    <Button size={'sm'} variant={'outline'} borderColor={'#009cf0'} color={'#009cf0'} mr={5} onClick={() => suggestionOnApply(index)} isLoading={isRescoring}>Check</Button>
                                    <Button size={'sm'} variant={'outline'} onClick={() => suggestionOnIgnore(index)}>Ignore</Button>
                                </CardFooter>
                            </>
                    </Card>
                ))}
            </Box>
        </Flex>
    )
}

export default SuggestionWrapper;