import { Box, Flex, Image, 
    // Tooltip, useBreakpointValue, Button, Icon, IconButton
    Link as ChakraLink, Center, VStack  } from "@chakra-ui/react";
// import { useTranslation } from "react-i18next";
// import { Search2Icon } from "@chakra-ui/icons";
// import { GiArchiveResearch } from "react-icons/gi";
// import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
// import { LuPenTool } from "react-icons/lu";
import { IoIosHelpCircle, IoIosInformationCircle } from "react-icons/io";
import { useRecoilState } from "recoil";
import { leftDrawerOpenStatus } from "../../atoms/rootAtom";
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from "react-icons/tb";

type ReaderSidebarProps = {
    sideBarOpenStatus: boolean;
    setSideBarOpenStatus: (status: boolean) => void; 
}

const ReaderSidebar = ({ sideBarOpenStatus, setSideBarOpenStatus }: ReaderSidebarProps) => {
    // const { t } = useTranslation();
    // const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });
    const [_drawerOpenStatus, setDrawerOpenStatus] = useRecoilState(leftDrawerOpenStatus);

    return (
        <>
            <Flex flexDir={'column'} bg={'white'} w={sideBarOpenStatus ? '70px' : '280px'} justifyContent={'space-between'} h={'full'} p={5} borderRight={'2px solid #e9e9e9'}>
                <Flex flexDir={'column'} h={'full'}>
                    <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        {!sideBarOpenStatus &&
                            <Center>
                                <Image
                                    w={'150px'}
                                    objectFit='cover'
                                    src='/doenba-logo-blue.png'
                                    alt='Doenba'
                                />
                            </Center>
                        }
                        <Box onClick={() => setSideBarOpenStatus(!sideBarOpenStatus)}>
                            { !sideBarOpenStatus &&
                                <TbLayoutSidebarLeftCollapse cursor={'pointer'} fontSize={'30px'} color="gray" />
                            }
                            { sideBarOpenStatus &&
                                <TbLayoutSidebarRightCollapse cursor={'pointer'} fontSize={'30px'} color="gray" />
                            }
                        </Box>
                    </Flex>
                    <Flex flexDir={'column'} mt={12} justifyContent={'space-between'} h={'full'}>
                        <Box>
                            Outline
                        </Box>
                        <VStack py={12}>
                            {!sideBarOpenStatus &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">Tutorials</ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">Helps</ChakraLink>
                                </>
                            }
                            {sideBarOpenStatus &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosInformationCircle color="#0057ec" />
                                    </ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosHelpCircle color="#0057ec" />
                                    </ChakraLink>
                                </>
                            }
                        </VStack>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default ReaderSidebar;