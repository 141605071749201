import PrivateRoute from "../PrivateRoute/PrivateRoute";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from '@chakra-ui/react'
import { Loading } from "../Loading";
import MyDrive from "../../pages/MyDrive";
import SearchResultWindow from "../SearchResult/SearchResultWindow";
import Notes from "../Notes/Note";
import OracleReaderComponent from "../OracleReaderComponent/OracleReaderComponent";
import Home from "../../pages/Home";
import TermsOfUse from "../../pages/TermsOfUse";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import Profile from '../../pages/Profile';
import LatexTest from "../LatexTest";
import TaskQATaskView from "../TaskViewer/TaskQATaskView";
import Writer from "../../pages/Writer";
import WriterEditorWrapper from "../../pages/WriterEditorWrapper";
import MyPlanPage from "../../pages/MyPlan";
import ReaderComponent from "../OracleReaderComponent/ReaderComponent";
import PaymentPage from "../../pages/PaymentPage";
// import EditableTest from "../../pages/WriterTests/Editable";

const MainWrapper = () => {    
    return (
        <Box overflow={'hidden'} w='full' h='full'>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<PrivateRoute><SearchResultWindow /></PrivateRoute>} />
                <Route path="/reader" element={<PrivateRoute><OracleReaderComponent /></PrivateRoute>} >
                    <Route path={"/reader/"} element={<TaskQATaskView />} />
                    <Route path={"/reader/notes"} element={<Notes />} />
                </Route>
                <Route path="/reader2" element={<PrivateRoute><ReaderComponent /></PrivateRoute>} >
                    <Route path={"/reader2/"} element={<TaskQATaskView />} />
                    <Route path={"/reader2/notes"} element={<Notes />} />
                </Route>
                <Route path="/editor" element={<PrivateRoute><Writer /></PrivateRoute>} />
                <Route path="/editor/:paperId" element={<PrivateRoute><WriterEditorWrapper /></PrivateRoute>}>
                </Route>
                <Route path="/my-library" element={<PrivateRoute><MyDrive /></PrivateRoute>} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/latex-test" element={<LatexTest />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/my-plan" element={<MyPlanPage />} />
                <Route path="/payment" element={<PaymentPage />} /> {/* Payment Page */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Loading />
        </Box>
    );
}

export default MainWrapper;
