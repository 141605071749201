import React, { useState } from "react";
import { Flex, Text, IconButton, useToast, Box, Select } from "@chakra-ui/react";
import { FaBold, FaItalic, FaUnderline, FaListUl, FaAlignCenter, FaAlignLeft, FaAlignRight, FaRegFileImage } from "react-icons/fa";

interface ToolboxProps {
    onApplyStyle: (style: string) => void;
}

const Toolbox: React.FC<ToolboxProps> = ({ onApplyStyle }) => {
    const [sortOption, setSortOption] = useState<string>("");
    const toast = useToast();

    const handleClick = (style: string) => {
        onApplyStyle(style);
        toast({
            title: `Applied ${style} style`,
            status: "info",
            duration: 1000,
            isClosable: true,
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortOption(event.target.value);
    };

    return (
        <Flex flexDir={'row'} mb={4} gap={2} w={'full'} justifyContent={'center'}>
            <Flex flexDir={'row'} alignItems={'center'} justifyContent={'center'} minW={'450px'} bg={'gray.100'} py={2} px={5} border={'1px solid #ccc'} borderRadius={'lg'}>
                <IconButton
                    size={'sm'}
                    icon={<FaBold />}
                    aria-label="Bold"
                    onClick={() => handleClick("bold")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaItalic />}
                    aria-label="Italic"
                    onClick={() => handleClick("italic")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaUnderline />}
                    aria-label="Underline"
                    onClick={() => handleClick("underline")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaAlignLeft />}
                    aria-label="List"
                    onClick={() => handleClick("list")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaAlignCenter />}
                    aria-label="List"
                    onClick={() => handleClick("list")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaAlignRight />}
                    aria-label="List"
                    onClick={() => handleClick("list")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaListUl />}
                    aria-label="List"
                    onClick={() => handleClick("list")}
                />
                <IconButton
                    size={'sm'}
                    icon={<FaRegFileImage />}
                    aria-label="List"
                    onClick={() => handleClick("list")}
                />
                <Box mx={2}>
                    <Select
                        value={sortOption}
                        onChange={handleChange}
                        variant="outline"
                        borderColor="gray.300"
                        size={'xs'}
                        _hover={{ borderColor: "gray.400" }}
                    >
                        <option value="modifiedDate">H1</option>
                        <option value="openedDate">H2</option>
                        <option value="title">H3</option>
                    </Select>
                </Box>
                <Text as={'b'} fontSize={'12px'}>1000 Words</Text>
            </Flex>
        </Flex>
    );
};

export default Toolbox;
