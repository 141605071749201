import { Box, Heading, ListItem, OrderedList } from "@chakra-ui/react"

const TermsWrapper = () => {
    return (
        <Box px={5} py={2}>
            <Heading size={'md'}>Terms</Heading>
            <Box mt={5}>
                <OrderedList>
                    <ListItem mb={2}>Lorem ipsum dolor sit amet</ListItem>
                    <ListItem mb={2}>Consectetur adipiscing elit</ListItem>
                    <ListItem mb={2}>Integer molestie lorem at massa</ListItem>
                    <ListItem mb={2}>Facilisis in pretium nisl aliquet</ListItem>
                </OrderedList>
            </Box>
        </Box>
    )
}

export default TermsWrapper;