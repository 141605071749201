import axios from "axios";
import { BASE_URL } from ".";
import { Paper } from "../types/Writer/Paper";


class TemplateService {
  public static async getAllTemplates(): Promise<Array<Paper>> {
    const response = await axios.get(BASE_URL + "/v2/templates");
    return response.data;
  }
}

export default TemplateService;
