import React, { useState, useRef } from 'react';
import {
  // Box,
  // Box, 
  //   Editable,
  // EditableTextarea,
  // EditablePreview,
  Flex,
  // Textarea,
} from '@chakra-ui/react';
// import { useOutsideClick } from '@chakra-ui/react';
// import { MathJax } from 'better-react-mathjax';
import 'katex/dist/katex.min.css';
// import { GrAggregate } from 'react-icons/gr';
// import { FaRegLightbulb } from 'react-icons/fa';
// import { BlockTypeSelect, BoldItalicUnderlineToggles, 
//   toolbarPlugin, UndoRedo, codeBlockPlugin, 
//   CodeToggle, CreateLink, 
//   codeMirrorPlugin, InsertCodeBlock, 
//   InsertImage, 
//   InsertTable, InsertThematicBreak, ListsToggle, MDXEditor, 
//   // ChangeAdmonitionType, 
//   // ChangeCodeMirrorLanguage, 
//   // DiffSourceToggleWrapper, InsertAdmonition, 
//   // InsertFrontmatter, 
//   // InsertSandpack, 
//   // quotePlugin, ShowSandpackInfo, 
//   // sandpackPlugin, ConditionalContents, 
//   tablePlugin, listsPlugin } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { PaperOutline, PaperOutlineNode } from '../../types/Writer/OutlineNode';
// import { getParagraphContent } from '../../utils/outlineNodeExtractor';
// import EditableTest from '../../pages/WriterTests/Editable';
import PaperFlaternContent from './PaperFlaternContent';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import {
//   ClassicEditor,
//   Bold,
//   Essentials,
//   Heading,
//   Indent,
//   IndentBlock,
//   Italic,
//   Link,
//   List,
//   MediaEmbed,
//   Paragraph,
//   Table,
//   Undo
// } from 'ckeditor5';

// import 'ckeditor5/ckeditor5.css';

interface EditableTextProps {
  outline: PaperOutline;
  nodeContent: PaperOutlineNode;
  updatePaperNodeContent: (value: string) => void;
  handleInputFocus: () => void;
  savePaper: () => void;
  // selectedContextRephrase: (context: string) => void;
}

const ParagraphEditor: React.FC<EditableTextProps> = ({
  // outline, 
  nodeContent,
  // updatePaperNodeContent 
}) => {
  console.log(nodeContent)
  const [_isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  // const [content, setContent] = useState<string>(JSON.stringify(nodeContent));

  // const textareaRef = useRef<HTMLDivElement | null>(null);
  // const [articleContent, setArticleContent] = useState<string>(nodeContent ? nodeContent : '');

  // useOutsideClick({
  //   ref: ref,
  //   handler: () => { 
  //       setIsEditing(false);
  //       updatePaperNodeContent(articleContent);
  //       // savePaper();
  //   }
  // });


  return (
    <Flex flexDir={'row'}
      justifyContent={'center'}
      w={'full'}
      h={'full'}
      ref={ref}
      onDoubleClick={() => setIsEditing(true)}>
      {/* <Editable defaultValue={`123 we have we have we have we have {"\\(\\frac{10}{4x} \\approx 2^{12}\\)"} we have we have we have we have`}>
            <MathJax>
                <EditablePreview />
            </MathJax>
            <EditableTextarea />
        </Editable> */}

      <PaperFlaternContent data={[nodeContent]}/>


      {/* <CKEditor
        editor={ClassicEditor}
        data={content}
        config={{
          toolbar: [
            'undo', 'redo', '|',
            'heading', '|', 'bold', 'italic', '|',
            'link', 'insertTable', 'mediaEmbed', '|',
            'bulletedList', 'numberedList', 'indent', 'outdent'
          ],
          plugins: [
            Bold,
            Essentials,
            Heading,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            MediaEmbed,
            Paragraph,
            Table,
            Undo
          ],
          initialData: '<h1>Hello from CKEditor 5!</h1>',
        }}
      /> */}

      {/* {isEditing ? (
        <Box ref={textareaRef} w={'full'}>
          <EditableTest nodes={getParagraphContent(nodeContent)} />
        <MDXEditor 
          markdown={''}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  {' '}
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  <CodeToggle/>
                  <CreateLink/>
                  <InsertCodeBlock/>
                  <InsertImage/>
                   <InsertTable/>
                  <InsertThematicBreak/>
                  <ListsToggle/>
                </>
              )
            }),
            codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
            codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),
            tablePlugin(),
            listsPlugin()
          ]}
          autoFocus
          // onChange={setArticleContent} 
          contentEditableClassName='prose'
        /> */}
      {/* 
      {showMenu && selectedText && (
        <Box
          position="absolute"
          top={`${menuPosition.y}px`}
          left={`${menuPosition.x}px`}
          bg="white"
          boxShadow="md"
          p={2}
          borderRadius="md"
          zIndex={10}
        >
          <HStack spacing='5px'>
            <Tooltip label='Rephraser'>
              <IconButton size={'xs'} aria-label='Rephraser' icon={<IoDiamondOutline />} onClick={() => {
                setShowMenu(false)
                selectedContextRephrase(selectedText)
              }} />
            </Tooltip>
            <Tooltip label='Quote and Q&A'>
              <IconButton size={'xs'} aria-label='Q&A' icon={<BsChatLeftQuote />} onClick={() => setShowMenu(false)} />
            </Tooltip>
          </HStack>
        </Box>
      )} */}
      {/* </Box>
      ) : (
        <Box>
            <MathJax>

            </MathJax>
            { getParagraphContent(nodeContent).map((node, index) => 
              <Box key={index} mb={5}>{node.content}</Box>
            ) }
        </Box>
      )} */}
    </Flex>
  );
};

export default ParagraphEditor;
