import React, { useRef, useState } from 'react';
import { Box, Button, Textarea, Input, Flex, Text, FormControl, FormLabel, VStack, Center, Heading, ListItem, OrderedList } from '@chakra-ui/react';
import { OutlineService } from '../../services';
import { PaperOutline } from '../../types/Writer/OutlineNode';
import { BibContent } from '../../types/Writer/BibContent';
import { PaperService } from '../../services/paperService';
import { FaCheckCircle } from 'react-icons/fa';
import { getNodeNames } from '../../utils/flatternPaperNodes';
import { HiOutlineUpload } from "react-icons/hi";
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { FaCheck } from "react-icons/fa6";

type DraftEditorProps = {
    onDraftFinish: ({
        draft,
        resources,
        outline
    }) => void;
    backToInit: () => void;
    paperTitle: string;
    paperTopic: string;
    setPaperTitle: (title: string) => void;
    setPaperTopic: (topic: string) => void;
}

const DraftEditor: React.FC<DraftEditorProps> = ({ onDraftFinish, backToInit, paperTitle, paperTopic, setPaperTitle, setPaperTopic }) => {
    const [draftContent, setDraftContent] = useState<string>('');
    const [_draftName, setDraftName] = useState<string>('');
    const [referenceFileName, setReferenceFileName] = useState<string>('');
    const [_resourceName, setResourcesName] = useState<string | null>(null);
    const [resources, setResources] = useState<BibContent[]>([]);
    const [moduleStep, setModuleStep] = useState<number>(0);
    // const [structure, setStructure] = useState<boolean>(true);
    // const [tone, setTone] = useState<boolean>(true);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [draftOutline, setDraftOutline] = useState<PaperOutline>(null);
    const [referenceOutline, setReferenceOutline] = useState<PaperOutline>(null);
    const [pickedOutline, setPickedOutline] = useState<number>(0);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleDraftUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setDraftName(file.name);
            setIsProcessing(true);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    setDraftContent(content);
                    OutlineService.extractOutline(content, true).then(
                        res => {
                            console.log(res);
                            setDraftOutline(res);
                            setIsProcessing(false)
                        }
                    )
                }
            };
            reader.readAsText(file);
        } else {
            setIsProcessing(false);
            alert('Please upload a valid .txt file');
        }
    };

    const handleReferenceUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setReferenceFileName(file.name);
            setIsProcessing(true);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    OutlineService.extractOutline(content, false).then(
                        res => {
                            setReferenceOutline(res);
                            setIsProcessing(false)
                        }
                    )
                }
            };
            reader.readAsText(file);
        } else {
            setIsProcessing(false);
            alert('Please upload a valid .txt file');
        }
    };

    const handleResourcesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setResourcesName(file.name);
            setIsProcessing(true);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    PaperService.extractBibContent(content).then(
                        res => {
                            setResources(res);
                            setIsProcessing(false)
                        }
                    )
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };

    const goPrevious = () => {
        console.log(133)
        if (moduleStep) {
            setModuleStep(moduleStep - 1);
        }

        if (moduleStep === 0) {
            backToInit();
        }
    }

    const goNext = () => {
        if (!isProcessing) {
            setModuleStep(moduleStep + 1);
            if (moduleStep === 0) {
                extractOutline();
            }
        }
    }

    const extractOutline = () => {
        OutlineService.extractOutline(draftContent, true).then(
            res => {
                console.log(res);
            }
        )
    }

    const draftOnFinish = () => {
        onDraftFinish({
            outline: pickedOutline === 0 ? draftOutline : referenceOutline,
            draft: draftContent,
            resources: resources
        });
    }

    const outlineOnChoosing = (index: number) => {
        setPickedOutline(index);
    }

    return (
        <Box p={3} w={'730px'} h={'404px'}>
            {moduleStep === 0 &&
                <Box h={'350px'}>
                    <Heading size={'md'} mb={1}>Upload Draft</Heading>
                    <Text color={'gray.400'} mt={5}>Upload your draft and we will refine and modify your structure for you. It can also be uploaded in sections when you go to the writing page.</Text>
                    <Flex flexDir={'row'} mt={4}>
                        <Box w={'160px'} mr={3}>
                            <Box
                                as="button"
                                onClick={handleClick}
                                p={5}
                                border="1px solid"
                                borderRadius="20px"
                                borderColor="gray.300"
                                textAlign="center"
                                cursor="pointer"
                                _hover={{ backgroundColor: "gray.100" }}
                                w={'160px'}
                                h={'199px'}
                                mb={5}
                                mt={2}
                            >
                                {!draftOutline && !isProcessing &&
                                    <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                        <Box w={'40px'} mb={3}>
                                            <HiOutlineUpload size={'lg'} color='#1d8aff' />
                                        </Box>
                                        <Text as={'b'} color={'gray.400'}>Drag to Upload</Text>
                                        <Text fontSize={12} color={'gray.400'}>Please upload pdf. words. txt. files</Text>
                                        <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' color='white'>Select</Button>

                                    </Flex>
                                }
                                {isProcessing &&
                                    <Flex flexDir={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}>
                                        <DonebaSpinner />
                                        <Text mt={5} color={'gray.400'}>File Uploading</Text>
                                        <Button size={'sm'} w='80%' mt='4' color='#1d8aff' borderColor={'#1d8aff'} variant={'outline'}>Select</Button>
                                    </Flex>
                                }
                                {draftOutline &&
                                    <Center>
                                        <FaCheckCircle color='green' fontSize={'35px'} />
                                    </Center>
                                }
                                <input
                                    ref={inputRef}
                                    type="file"
                                    accept=".txt"
                                    onChange={handleDraftUpload}
                                    style={{ display: "none" }}
                                />
                            </Box>
                            {/* <Text>{draftName}</Text> */}
                        </Box>
                        <Box w={'calc(100% - 160px)'} ml={5}>
                            <VStack spacing={3} align="start">
                                <FormControl>
                                    <FormLabel>What is your article title?</FormLabel>
                                    <Input
                                        type="text"
                                        bg={'white'}
                                        value={paperTitle}
                                        borderRadius={'12px'}
                                        onChange={(e) => setPaperTitle(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>
                                        <Flex flexDir={'row'} alignItems={'center'}>
                                            What is your writing topic? <Text ml={1} color='red'>*</Text>
                                        </Flex>
                                    </FormLabel>
                                    <Textarea
                                        rows={3}
                                        bg={'white'}
                                        value={paperTopic}
                                        borderRadius={'12px'}
                                        onChange={(e) => setPaperTopic(e.target.value)}
                                    />
                                </FormControl>
                            </VStack>
                        </Box>
                    </Flex>
                </Box>
            }

            {moduleStep === 1 &&
                <Box h={'350px'}>
                    <Heading size={'md'} mb={1}>Upload Structure Reference Article</Heading>
                    <Text color={'gray'}>Upload your reference article. We will analyze it with you to learn from your reference. You can also upload it later in the writing panel.</Text>
                    <Flex flexDir={'row'} mt={5} alignItems="center" justifyContent="center">
                        <Box
                            as="button"
                            onClick={handleClick}
                            p={4}
                            border="1px solid"
                            borderColor="gray.300"
                            borderRadius="20px"
                            textAlign="center"
                            cursor="pointer"
                            _hover={{ backgroundColor: "gray.100" }}
                            w={'316px'}
                            h={'192px'}
                            mb={10}
                        >
                            {!referenceOutline && !isProcessing &&
                                <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                    <Box w={'40px'} mb={3}>
                                        <HiOutlineUpload size={'lg'} color='#1d8aff' />
                                    </Box>
                                    <Text as={'b'} color={'gray.400'}>Drag to Upload</Text>
                                    <Text fontSize={12} color={'gray.400'}>Please upload pdf. words. txt. files</Text>
                                    <Button size={'sm'} bg='#1d8aff' w='40%' mt='4' color='white'>Select</Button>

                                </Flex>}
                            {isProcessing &&
                                <DonebaSpinner />
                            }
                            {referenceOutline &&
                                <Center>
                                    <FaCheckCircle color='green' fontSize={'35px'} />
                                </Center>
                            }
                            <input
                                ref={inputRef}
                                type="file"
                                accept=".txt"
                                onChange={handleReferenceUpload}
                                style={{ display: "none" }}
                            />
                        </Box>
                        <Text>{referenceFileName}</Text>
                    </Flex>
                </Box>
            }

            {moduleStep === 2 &&
                <Box h={'350px'}>
                    <Heading size={'md'} mb={1}>Upload Tone Reference Article</Heading>
                    <Text color={'gray'}>Upload your reference article. We will analyze it with you to learn from your reference. You can also upload it later in the writing panel.</Text>
                    <Flex flexDir={'row'} mt={5} alignItems="center" justifyContent="center">
                        <Box
                            as="button"
                            onClick={handleClick}
                            p={4}
                            border="1px solid"
                            borderColor="gray.300"
                            borderRadius="20px"
                            textAlign="center"
                            cursor="pointer"
                            _hover={{ backgroundColor: "gray.100" }}
                            w={'316px'}
                            h={'192px'}
                            mb={10}
                        >
                            {!referenceOutline && !isProcessing &&
                                <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                    <Box w={'40px'} mb={3}>
                                        <HiOutlineUpload size={'lg'} color='#1d8aff' />
                                    </Box>
                                    <Text as={'b'} color={'gray.400'}>Drag to Upload</Text>
                                    <Text fontSize={12} color={'gray.400'}>Please upload pdf. words. txt. files</Text>
                                    <Button size={'sm'} bg='#1d8aff' w='40%' mt='4' color='white'>Select</Button>

                                </Flex>}
                            {isProcessing &&
                                <DonebaSpinner />
                            }
                            {referenceOutline &&
                                <Center>
                                    <FaCheckCircle color='green' fontSize={'35px'} />
                                </Center>
                            }
                            <input
                                ref={inputRef}
                                type="file"
                                accept=".txt"
                                onChange={handleReferenceUpload}
                                style={{ display: "none" }}
                            />
                        </Box>
                        <Text>{referenceFileName}</Text>
                    </Flex>
                </Box>
            }

            {moduleStep === 3 &&
                <Flex flexDir={'column'} alignItems={'center'} h={'350px'}>
                    <Flex flexDir={'row'} justifyContent={'flex-start'} w={'full'}>
                        <Heading size={'md'} mb={1}>Suggest Structure</Heading>
                    </Flex>

                    <Flex flexDir={'column'} w={'650px'} mt={3} mb={4} >
                        <Flex flexDir={'row'} h={'274px'}>
                            <Flex flexDir={'column'} w={'50%'} borderRadius={'5px 0  0 5px'} border={'1px solid #ccc'} p={3} cursor={'pointer'} _hover={{ boxShadow: 'md' }} onClick={() => outlineOnChoosing(0)} bg={pickedOutline === 0 ? '#dfedff' : ''}>
                                <Flex flexDir={'row'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} mb={5}>
                                    {pickedOutline === 0 && <FaCheck color='#1d8aff' />}
                                    <Heading size='sm' ml={2} textAlign={'center'}>Your Structure</Heading>
                                </Flex>
                                <Box mt={3} overflow={'auto'} h='full'>
                                    {draftOutline && getNodeNames(structuredClone(draftOutline)).map(node => (
                                            <Box p={2} bg={'#c7dcff'} mb={2} borderRadius={'10px'} textAlign={'center'}>{node}</Box>
                                        ))
                                    }
                                    { !draftOutline &&
                                        <Center w='full' h={'full'}  color={'gray'}>
                                             <Box w={'70%'} >
                                                Upload structure draft to see your structure. 
                                            </Box>
                                        </Center>
                                    }
                                </Box>
                            </Flex>
                            <Flex flexDir={'column'} textAlign={'center'} w={'50%'} borderRadius={'0 5px 5px 0'} border={'1px solid #ccc'} p={3} cursor={'pointer'} _hover={{ boxShadow: 'md' }} onClick={() => outlineOnChoosing(1)} bg={pickedOutline === 1 ? '#dfedff' : ''}>

                                <Flex flexDir={'row'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} mb={5}>
                                    {pickedOutline === 1 && <FaCheck color='#1d8aff' />}
                                    <Heading size='sm' ml={2} textAlign={'center'}>Suggest Structure</Heading>
                                </Flex>
                                <Box mt={3} overflow={'auto'} h={'full'}>
                                    {referenceOutline && getNodeNames(structuredClone(referenceOutline)).map(node => (
                                        <Box p={2} bg={'c7dcff'} mb={2} borderRadius={'15px'} textAlign={'center'}>{node}</Box>
                                    ))}  
                                    { !referenceOutline &&
                                        <Center w='full' h={'full'} color={'gray'} fontSize={'14px'}>
                                            <Box w={'70%'} >
                                                Upload structure reference artilce to see suggest structure 
                                            </Box>
                                        </Center>
                                    } 
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }

            {
                moduleStep === 4 &&
                <Box h={'350px'}>
                    <Heading size={'md'} mb={1}>Upload Citation Resources</Heading>
                    <Text mt={4} color={'gray'}>Upload your resource materials to Doenba Editor to help us better understand your draft. You can upload <Text as='b'>pdf. txt.</Text> and <Text as='b'>bib.</Text> file. We will add your resources to your library.</Text>
                    <Flex flexDir={'row'} mt={5}>
                        <Box w={'200px'} mr={5}>
                            <Box
                                as="button"
                                onClick={handleClick}
                                p={4}
                                border="1px solid"
                                borderColor="gray.300"
                                borderRadius="md"
                                textAlign="center"
                                cursor="pointer"
                                _hover={{ backgroundColor: "gray.100" }}
                                w={'160px'}
                                h={'182px'}
                                mb={5}
                            >
                                {resources.length === 0 && !isProcessing &&
                                    <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                        <Box w={'40px'} mb={3}>
                                            <HiOutlineUpload size={'lg'} color='#1d8aff' />
                                        </Box>
                                        <Text as={'b'} color={'gray.400'}>Drag to Upload</Text>
                                        <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                                        <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' color='white'>Select</Button>

                                    </Flex>
                                }
                                {isProcessing &&
                                    <DonebaSpinner />
                                }
                                {resources.length > 0 &&
                                    <Center>
                                        <FaCheckCircle color='green' fontSize={'35px'} />
                                    </Center>
                                }

                                <input
                                    ref={inputRef}
                                    type="file"
                                    accept=".txt"
                                    onChange={handleResourcesUpload}
                                    style={{ display: "none" }}
                                />
                            </Box>
                            {/* <Text>{resourceName}</Text> */}
                        </Box>
                        <Box w={'calc(100% - 100px)'} h={'220px'} overflow={'auto'}>
                            <OrderedList spacing={3}>
                                {resources.map(resource => (
                                    <ListItem key={resource.json_info.doi}>
                                        <Box><Text as='b'>{resource.json_info.title} - {resource.json_info.year}</Text></Box>
                                        <Box><Text color={'gray.400'}>{resource.json_info.authors}</Text></Box>
                                    </ListItem>
                                ))}
                            </OrderedList>
                        </Box>
                    </Flex>
                </Box>
            }

            <Flex flexDir={'row'} justifyContent={'space-between'}>
                <Button onClick={goPrevious} size={'lg'} w={'139px'} bg={'#9399AB'} color={'white'} borderRadius={'15px'}>Back</Button>
                {(moduleStep === 1 || moduleStep === 2 || moduleStep === 3) &&
                    <Button variant={'link'}>Skip</Button>
                }
                {(moduleStep === 0 || moduleStep === 1 || moduleStep === 2 || moduleStep === 3) &&
                    <Button colorScheme='brand' onClick={goNext} isDisabled={isProcessing} size={'lg'} w={'139px'} borderRadius={'15px'}>
                        {/* {isProcessing && <Flex flexDir={'row'} alignItems={'center'}>
                            <Spinner size={'sm'} mr={2} />
                            Extracting outline
                        </Flex>} */}
                        {/* {!isProcessing && 'Next'} */}
                        Next
                    </Button>
                }
                {moduleStep === 4 &&
                    <Button colorScheme='brand' size={'lg'} onClick={draftOnFinish} w={'139px'} borderRadius={'15px'}>Start</Button>
                }
            </Flex>
        </Box >
    );
};

export default DraftEditor;
