import { BASE_URL } from ".";
import { sseFetch } from "../utils/sseFetch";
import { WriterConversationMessage } from "../types/Writer/Conversation";
import axios from "axios";

const url = BASE_URL + "/writerConversations";

export class WriterConversationService {
	public static async fetchWriterConversationMessagesForPaper(paperId: string) {
		const response = await axios.get<WriterConversationMessage[]>(url + "/paper/" + paperId);
		return response.data;
	}

	public static async addToWriterConversation(
		paperId: string,
		nodeId: number,
		message: string,
		// nodeContent: string,
		onPartialBotResponse: (message: string) => void,
		onWriterConversationMessagesResponse: (response: WriterConversationMessage[]) => void,
	): Promise<void> {
		await sseFetch(url + "/paper/" + paperId, 
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ message, nodeId }),
				openWhenHidden: true,
				onmessage(msg) {
					switch (msg.event) {
						case 'bot':
							onPartialBotResponse(JSON.parse(msg.data));
							break;
						case 'writerConversationMessages':
							onWriterConversationMessagesResponse(JSON.parse(msg.data));
							break;
						case 'error':
							throw new Error(msg.data);
						default:
							console.log("Unknown event", msg.event);
					}
				},
				onerror(err) {
					throw err; // Stop retrying
				}
			},
		);
	}
}