import React, { useState } from 'react';
import {
  Box,
  Avatar,
  Text,
  Heading,
  Flex,
  useToast,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from '../components/NavBar/Components/UserMenu';

const Profile: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  const [username, setUsername] = useState<string>('John Doe');
  const [email, setEmail] = useState<string>('johndoe@example.com');
  const [bio, setBio] = useState<string>('A short bio about yourself');
  const toast = useToast();
  const handleSaveChanges = () => {
    // Here, you would typically handle API calls to save changes
    toast({
      title: 'Profile Updated',
      description: 'Your profile details have been saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Flex px={5} py={2} flexDir={'row'} justifyContent={'flex-end'}>
        {isAuthenticated && user && (
          <UserMenu user={user} />
        )}
      </Flex>
      <Flex
        maxW="1000px"
        mx="auto"
        mt="8"
        p="6"
        gap={6}
        align="start"
        justify="center"
        wrap="wrap"
      >
        {/* Profile Card */}
        <Box
          bg="white"
          p={6}
          rounded="md"
          boxShadow="md"
          w={['full', 'full', '300px']}
          textAlign="center"
        >
          <Avatar size="xl" name={username} src="https://via.placeholder.com/150" mb={4} />
          <Heading as="h2" size="md" mb={2}>
            {username}
          </Heading>
          <Text color="gray.500" mb={2}>
            {email}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {bio}
          </Text>
        </Box>

        {/* Profile Edit Form */}
        <Box
          bg="white"
          p={6}
          rounded="md"
          boxShadow="md"
          flex="1"
          minW={['full', 'full', '400px']}
        >
          <Heading as="h3" size="lg" mb={6}>
            Edit Profile
          </Heading>
          <VStack spacing={4} align="stretch">
            {/* Username Field */}
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={username}
                placeholder="Enter your name"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>

            {/* Email Field */}
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            {/* Bio Field */}
            <FormControl>
              <FormLabel>Bio</FormLabel>
              <Input
                type="text"
                value={bio}
                placeholder="A short bio about yourself"
                onChange={(e) => setBio(e.target.value)}
              />
            </FormControl>

            {/* Action Buttons */}
            <HStack justifyContent="flex-end" pt={4}>
              <Button colorScheme="gray" variant="outline" onClick={() => { setUsername('John Doe'); setEmail('johndoe@example.com'); setBio('A short bio about yourself'); }}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Profile;