import { OutlineNodeType, PaperOutlineNode } from "../types/Writer/OutlineNode";

/**
 * Get an outline node by its id
 * @param nodeId The id of the node to find
 * @param nodes The list of nodes to search
 */
export function getOutlineNodeById(nodeId: number, nodes: PaperOutlineNode[] | Omit<PaperOutlineNode, "children">[]): PaperOutlineNode | Omit<PaperOutlineNode, "children"> | undefined {
	for (const node of nodes) {
		if (node.id === nodeId) {
			return node;
		}

		if ("children" in node) {
			const result = getOutlineNodeById(nodeId, node.children);
            if (result !== undefined) {
                return result;
            }
		}
	}

	return undefined;
}

export const getParagraphContent = (node: PaperOutlineNode): PaperOutlineNode[] => {
	let paragraphs: PaperOutlineNode[] = [];
  
	// If the node is a paragraph, add its content
	if (node.type === OutlineNodeType.Paragraph && node.content) {
	  paragraphs.push(node);
	}
  
	// If the node has children, recursively check them
	if (node.children && node.children.length > 0) {
	  node.children.forEach(child => {
		paragraphs = paragraphs.concat(getParagraphContent(child));
	  });
	}

	return paragraphs;
  };