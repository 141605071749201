import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from '../components/NavBar/Components/UserMenu';
import { useNavigate } from 'react-router-dom';

const MyPlanPage: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const handleSubscribe = () => {
      navigate('/payment'); // Redirect to payment page
    };
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');
  const plans = [
    {
      name: 'Basic Plan',
      price: 0,
      priceDescription: '/month',
      features: [
        '50 credits renew daily (10 songs)',
        'Non-commercial terms',
        'No credit top ups',
        'Shared generation queue',
        '2 running jobs at once',
      ],
      buttonText: 'Active',
      buttonColor: 'purple.500',
      isActive: true,
    },
    {
      name: 'Pro Plan',
      price: billingCycle === 'monthly' ? 10 : 96, // $10 per month or $96 yearly (20% off)
      priceDescription: '/month',
      features: [
        '2,500 credits renew monthly (500 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      buttonColor: 'orange.400',
      isActive: false,
    },
    {
      name: 'Premier Plan',
      price: billingCycle === 'monthly' ? 30 : 288, // $30 per month or $288 yearly (20% off)
      priceDescription: '/month',
      features: [
        '10,000 credits renew monthly (2,000 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      buttonColor: 'purple.400',
      isActive: false,
    },
  ];

  return (
    <Box>
      <Flex px={5} py={2} flexDir={'row'} justifyContent={'flex-end'}>
                        {isAuthenticated && user && (
                            <UserMenu user={user} />
                        )}
                    </Flex>
      <Box py={10} px={5} maxW="1000px" mx="auto">
        <Heading as="h1" size="lg" textAlign="center" mb={2}>
          Manage Subscription
        </Heading>
        <Text textAlign="center" mb={6}>
          Choose the plan that works for you.
        </Text>

        {/* Billing cycle toggle */}
        <Flex justifyContent="center" mb={8}>
          <Button
            variant={billingCycle === 'monthly' ? 'solid' : 'outline'}
            onClick={() => setBillingCycle('monthly')}
            mx={2}
          >
            Monthly billing
          </Button>
          <Button
            variant={billingCycle === 'yearly' ? 'solid' : 'outline'}
            onClick={() => setBillingCycle('yearly')}
            mx={2}
          >
            Yearly billing
          </Button>
        </Flex>

        {/* Subscription Plans */}
        <Flex wrap="wrap" justifyContent="center" gap={6}>
          {plans.map((plan, index) => (
            <Box
              key={index}
              bg="white" 
              color="black"
              p={6}
              rounded="md"
              w="full"
              maxW="300px"
              textAlign="center"
              boxShadow="md"
            >
              <Heading as="h3" size="md" mb={2}>
                {plan.name}
              </Heading>
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                ${plan.price}
                <Text as="span" fontSize="lg" fontWeight="normal" color={'black'}>
                  {plan.priceDescription}
                </Text>
              </Text>
              {billingCycle === 'yearly' && plan.price > 0 && (
                <Text fontSize="sm" color="black" mb={4}>
                  Save with yearly billing (20% off)
                </Text>
              )}
              <VStack align="start" spacing={2} mb={4} color={'black'}>
                {plan.features.map((feature, idx) => (
                  <Text key={idx} fontSize="sm" color="black">
                    • {feature}
                  </Text>
                ))}
              </VStack>
              <Button
              w="full"
              colorScheme={plan.isActive ? 'gray' : 'blue'}
              onClick={!plan.isActive ? handleSubscribe : undefined}
              isDisabled={plan.isActive} // Disable if plan is active
            >
              {plan.buttonText}
            </Button>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default MyPlanPage;