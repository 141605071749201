import axios from "axios";

import { BASE_URL } from ".";
import { WriterSearchResponse } from "../types/Writer/WriterSearchResponse";

const url = BASE_URL + "/writer";

export class WriterService {
	public static async search(search: string) {
		const response = await axios.get<WriterSearchResponse>(url + "/search", { params: { search } });
		return response.data;
	}
}