import { User } from "@auth0/auth0-react";
import {
	Avatar,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Switch,
	useDisclosure,
	Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { isNewUI } from "../../../atoms/rootAtom";

interface Props {
	user: User;
}

const UserMenu = ({ user }: Props) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [newUIStatus, setNewUIStatus] = useRecoilState(isNewUI);

	const newUIOnSwitch = () => {
		setNewUIStatus(!newUIStatus);
		navigate('/reader2')
	}

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					border="25px"
					aria-label="Options"
					icon={<Avatar size="md" name={user.name} src={user.picture} />}
					variant="outline"
				>
					{"Profile"}
				</MenuButton>
				<MenuList zIndex={3}>
					<MenuItem onClick={() => navigate("/my-library")}>{t('my-library-title')}</MenuItem>
					<MenuItem onClick={() => navigate("/my-plan")}>{t('my-plan-label')}</MenuItem>
					<MenuItem onClick={() => navigate("/profile")}>{t('my-profile-label')}</MenuItem>
					<Flex flexDir="row" alignItems={'center'} px={5} py={2}>
							<Switch mr={2} size="sm" checked={newUIStatus} onChange={newUIOnSwitch}/>
							<Text>New UI</Text>
						</Flex>
					<MenuDivider />
					<MenuItem onClick={onOpen}>{t('logout-label')}</MenuItem>
				</MenuList>
			</Menu>

			<LogoutModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export default UserMenu;
