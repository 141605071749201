import React from 'react';
import { Box, Center, Spinner, Image } from "@chakra-ui/react"

const DonebaSpinner: React.FC = () => {
    return <Box pos={'relative'} w={'50px'} h={'50px'}>
        <Center w='full' h={'full'} pt={5} pos={'absolute'} top={'-11px'} left={'1px'}>
            <Image
                w={'20px'}
                objectFit='cover'
                src='/doenba-ico-blue.png'
                alt='Doenba'
            />
        </Center>
        <Spinner
            thickness='3px'
            speed='0.65s'
            emptyColor='gray.200'
            color='#0057d9'
            size='xl' />
    </Box>;
}

export default DonebaSpinner;