import { Box, Heading } from "@chakra-ui/react"
import SessionDrawer from "../TaskViewer/SessionDrawer";

type ReadSessionsWrapperProps = {
    sessionDrawerOpenStatus: boolean;
    setSessionDrawerOpenStatus: (status: boolean) => void;
    currentConversation,
    filteredConversations,
    addNewConversation,
    conversationOnClick,
    deleteConversation,
    downloadTranscript,
    showAllQA,
    setShowAllQA
}

const ReadSessionsWrapper = ({
    sessionDrawerOpenStatus,
    setSessionDrawerOpenStatus,
    currentConversation,
    filteredConversations,
    addNewConversation,
    conversationOnClick,
    deleteConversation,
    downloadTranscript,
    showAllQA,
    setShowAllQA
}: ReadSessionsWrapperProps) => {
    return (
        <Box px={5} py={2}>
            <Heading size={'md'}>Read Sessions</Heading>
            <SessionDrawer
                isOpen={sessionDrawerOpenStatus}
                handleClose={() => setSessionDrawerOpenStatus(false)}
                currentConversation={currentConversation}
                conversations={filteredConversations}
                addNewConversation={addNewConversation}
                conversationOnClick={conversationOnClick}
                deleteConversation={deleteConversation}
                downloadTranscript={downloadTranscript}
                showAllQA={showAllQA}
                setShowAllQA={setShowAllQA}
            />
        </Box>
    )
}

export default ReadSessionsWrapper;