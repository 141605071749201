import axios from "axios";
import { BASE_URL } from ".";
import { CreatePaymentIntentRequest, CreatePaymentRequest } from "../types/Payment";


export class PaymentService {
    public static async createPaymentIntent(data: CreatePaymentIntentRequest) {
        const response = await axios.post(BASE_URL + '/payment/create-intent', data);
        return response.data;
    }

    public static async createPaymen(data: CreatePaymentRequest) {
        const response = await axios.post(BASE_URL + '/payment/create', data);
        return response.data;
    }
}