import { Box, Heading } from "@chakra-ui/react"

const LibraryWrapper = () => {
    return (
        <Box px={5} py={2}>
            <Heading size={'md'}>Library</Heading>
            <Box>123</Box>
        </Box>
    )
}

export default LibraryWrapper;