import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading
} from '@chakra-ui/react';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { PaymentService } from '../services/paymentService';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from '../components/NavBar/Components/UserMenu';

const stripePromise = loadStripe('pk_test_51QIFFhRxWNUC1BhkJGyVMvtZnmvWFFHqa2U4xuZRzNSg92I0Ymxkh1BgAiuxpQ6rAttD3fsPC5xOLLozxWF2grc6005Fv8brGZ');

const cardElementOptions = {
  style: {
    base: {
      color: '#303238',      // Base text color
      fontSize: '16px',       // Base font size
      iconColor: '#666EE8',   // Icon color
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      '::placeholder': {
        color: '#CFD7DF',     // Placeholder color
      },
    },
    invalid: {
      color: '#e5424d',       // Color for invalid card information
      iconColor: '#e5424d',   // Icon color when invalid
    },
  },
  hidePostalCode: true,       // Optionally hide the postal code field
};

interface PaymentFormProps { }

const PaymentForm: React.FC<PaymentFormProps> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    let response = null;

    PaymentService.createPaymentIntent({
      amount: 1,
      currency: 'usd'
    }).then(
      res => response = res
    )

    const { clientSecret }: { clientSecret: string } = await response.json();

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: 'Customer Name',
        },
      },
    });

    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent?.status === 'succeeded') {
        alert('Payment successful!');
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={cardElementOptions} />
      <Button mt={12} type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : 'Pay Now'}
      </Button>
    </form>
  );
};

const StripeWrapper: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <Box p={5} w={'full'} h={'full'}>
      <Flex flexDir={'row'} justifyContent={'flex-end'} pl='2' mr='2'>
          {isAuthenticated && user && (
              <UserMenu user={user} />
          )}
      </Flex>

      <Center  w={'full'} h={'full'} flexDir={'column'}>
        <Flex flexDir={'row'} justifyContent={'center'}>
          <Heading size='md'>Payment Center</Heading>
        </Flex>
        <Card mt={12} minW={'600px'}>
          <CardBody>
            <Box>
              <Elements stripe={stripePromise}>
                <PaymentForm />
              </Elements>
            </Box>
          </CardBody>
        </Card>
      </Center>
    </Box>
  );
};

export default StripeWrapper;