import { useState, useEffect, useMemo } from "react";
import {
    Box, Flex, IconButton, Button,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import { LuClipboardSignature, 
    // LuZoomIn, LuZoomOut 
} from 'react-icons/lu';
import { FileService } from "../../services";
import { useRecoilState, useSetRecoilState } from "recoil";
import { appLoadingState, appLoadingText, currentOpenedArticleIdState, quoteSentenceIndexesState, selectedArticlesState, 
    // selectedMessageBubbleId 
} from "../../atoms/rootAtom";
// import MiniNav from "../NavBar/MiniNav";
// import { AiOutlineFullscreen } from "react-icons/ai";
// import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { UserFileDataService } from "../../services/userFileDataService";
import { ClientError } from "../../utils/clientError";
import useUnprocessedFileHandler from "../../hooks/useUnprocessedFileHandler";
import { containsFeatures } from "../../services/articleFeatureService";
import { useTranslation } from "react-i18next";
// import FeatureWrapper from "../FeatureWrapper/FeatureWrapper";
import { ArticleFeature } from "../../types/ArticleFeature";
// import TopicSearch from "./TopicSearch";
// import { Outlet } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";
import useHighlights, { HighlightType } from "../../hooks/useHighlights";
// import { TbEraserOff } from "react-icons/tb";
// import SideNav from "../NavBar/SideNav";
import ReaderSidebar from "../NavBar/ReaderSidebar";
import { useAuth0 } from "@auth0/auth0-react";
import UserMenu from "../NavBar/Components/UserMenu";
import LangSelect from "../NavBar/LangSelect";
import { CloseIcon } from "@chakra-ui/icons";
import HighlightSentencesWrapper from "./HighlightSentencesWrapper";
import TermsWrapper from "./TermsWrapper";
import ReadSessionsWrapper from "./ReadSessionsWrapper";
import LibraryWrapper from "./LibraryWrapper";
import ArticleTitleSelectionWrapper from "./ArticleTitleSelectionWrapper";


const ReaderComponent = () => {
    const [floatingDivPosition, _setFloatingDivPosition] = useState<{ top: number; left: number } | null>(null);
    const [selectionContext, _setSelectedContext] = useState<string | null>(null);
    const [hasCompleteSelection, setHasCompleteSelection] = useState<boolean>(false);
    const [selectionSentenceIndexes, _setSelectionSentenceIndexes] = useState<number[]>([]);
    const [htmlContent, setHtmlContent] = useState('');
    const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);
    const [currentOpenArticleId, setCurrentOpenArticleId] = useRecoilState(currentOpenedArticleIdState);
    const setQuoteSentenceIndexes = useSetRecoilState(quoteSentenceIndexesState);
    const [zoomLevel, _setZoomLevel] = useState<number>(-2);
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
    const { user, isAuthenticated } = useAuth0();
    const [sideBarOpenStatus, setSideBarOpenStatus] = useState<boolean>(false);


    const setAppLoadingState = useSetRecoilState(appLoadingState);
    const setLoadingText = useSetRecoilState(appLoadingText);
    const { 
        addNeededFilesToPoller, 
        // hasFileProcessedSuccessfully 
    } = useUnprocessedFileHandler();
    const [_isInReadingList, setIsInReadingList] = useState<boolean>(false);

    const { t } = useTranslation();
    const { clearHighlights, highlightSentences } = useHighlights();

    // const setSelectedMessageId = useSetRecoilState(selectedMessageBubbleId);

    const [toolWindow, setToolWindow] = useState<string>('');


    const currentlySelectedArticle = useMemo(() =>
        selectedArticles.find((article) => article.id === currentOpenArticleId),
        [currentOpenArticleId, selectedArticles]
    );

    // const hasCurrentArticleProcessedSuccessfully = currentlySelectedArticle && hasFileProcessedSuccessfully(currentlySelectedArticle);

    const [toolWindowOpenStatus, setToolWindowOpenStatus] = useState<boolean>(false);
    const [sessionDrawerOpenStatus, setSessionDrawerOpenStatus] = useState<boolean>(false);

    useEffect(() => {
        if (currentlySelectedArticle) {
            addNeededFilesToPoller(currentlySelectedArticle);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlySelectedArticle]);

    const fileOnSelect = async () => {
        setLoadingText(t('global-getting-article-label'));
        setAppLoadingState(true);
        try {
            const html = await FileService.downloadHtmlFile(currentOpenArticleId);
            setHtmlContent(html);

            setTimeout(() => {
                const targetSentence = document.querySelector(`div[id="page-container"]`);
                if (targetSentence instanceof HTMLElement) {
                    targetSentence.style.backgroundColor = `#ededed`;
                    targetSentence.style.backgroundImage = 'none';
                } else {
                    console.error('"targetSentence" not found.');
                }

                const articlePages = document.querySelectorAll('[id^="pf"]');

                articlePages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1)`;
                        page.style.margin = `25px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.error('"page" not found.');
                    }
                })

                const articleFirstPage = document.querySelector(`div[id="pf1"]`);
                if (articleFirstPage instanceof HTMLElement) {
                    articleFirstPage.style.margin = `25px auto 25px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
            }, 100)

            setTimeout(() => {
                const targetSentence = document.querySelector(`div[id="page-container"]`);
                if (targetSentence instanceof HTMLElement) {
                    targetSentence.style.transform = `scale(1)`;
                } else {
                    console.error('"targetSentence" not found.');
                }
            }, 100)

            setAppLoadingState(false);
        } catch (err) {
            console.log('Fetching article error: ', err);
            setAppLoadingState(false);
        }
    }
    useEffect(() => {
        if (currentOpenArticleId !== '') {
            fileOnSelect();
        }
    }, [currentOpenArticleId])

    // Needed for if the file in selectedArticles changes, need to re-fetch the article
    useEffect(() => {
        if (selectedArticles.find(articles => articles.id === currentOpenArticleId)) {
            fileOnSelect();
        }
    }, [currentOpenArticleId, selectedArticles]);

    // const handleMouseUp = () => {
    //     setHasCompleteSelection(true);
    //     const selection = window.getSelection();
    //     if (selection && selection.rangeCount > 0) {
    //         const selectedText = selection.toString();
    //         const range = selection.getRangeAt(0);
    //         const boundingRect = range.getBoundingClientRect();

    //         // Function to collect unique s_index values within the range
    //         const collectSIndexInRange = (range) => {
    //             const sIndexSet = new Set<number>();

    //             const treeWalker = document.createTreeWalker(
    //                 range.commonAncestorContainer,
    //                 NodeFilter.SHOW_ELEMENT,
    //                 {
    //                     acceptNode: (node) => {
    //                         const nodeRange = document.createRange();
    //                         nodeRange.selectNode(node);
    //                         return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
    //                     },
    //                 }
    //             );

    //             let currentNode = treeWalker.currentNode;
    //             while (currentNode) {
    //                 if (
    //                     currentNode.nodeType === Node.ELEMENT_NODE &&
    //                     (currentNode as Element).hasAttribute('s_index')
    //                 ) {
    //                     const sIndexValue = parseInt((currentNode as Element).getAttribute('s_index'), 10);
    //                     if (!isNaN(sIndexValue)) {
    //                         sIndexSet.add(sIndexValue);
    //                     }
    //                 }
    //                 currentNode = treeWalker.nextNode();
    //             }

    //             // Also check the start and end containers themselves
    //             const checkNode = (node) => {
    //                 if (node.nodeType === Node.ELEMENT_NODE && (node as Element).hasAttribute('s_index')) {
    //                     const sIndexValue = parseInt((node as Element).getAttribute('s_index'), 10);
    //                     if (!isNaN(sIndexValue)) {
    //                         sIndexSet.add(sIndexValue);
    //                     }
    //                 }
    //             };
    //             checkNode(range.startContainer.parentElement);
    //             checkNode(range.endContainer.parentElement);

    //             return Array.from(sIndexSet).sort((a, b) => a - b);
    //         };

    //         const uniqueSIndexArray = collectSIndexInRange(range);

    //         setFloatingDivPosition({
    //             top: boundingRect.top,
    //             left: boundingRect.left,
    //         });

    //         setSelectedContext(selectedText);
    //         setSelectionSentenceIndexes(uniqueSIndexArray);
    //     }
    // };

    useEffect(() => {
        const allPages = document.querySelectorAll('[id^="pf"]');
        const firstPage = document.querySelector(`div[id="pf1"]`);

        switch (zoomLevel) {
            case -3:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(0.8)`;
                        page.style.margin = `-150px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `-70px auto -150px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case -2:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1)`;
                        page.style.margin = `25px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `25px auto 25px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case -1:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.2)`;
                        page.style.margin = `190px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `105px auto 190px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 0:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.5)`;
                        page.style.margin = `450px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `240px auto 450px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 1:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.7)`;
                        page.style.margin = `630px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `330px auto 630px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 2:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(2)`;
                        page.style.margin = `900px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `500px auto 900px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            default:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.5)`;
                        page.style.margin = `450px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `240px auto 450px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
        }
    }, [zoomLevel])

    //Deep linkning
    useEffect(() => {
        const updateUrl = (articleId) => {

            const url = new URL(window.location.href);

            url.searchParams.set('id', articleId);

            window.history.pushState({ path: url.toString() }, '', url.toString());
        };

        if (currentOpenArticleId) {
            updateUrl(currentOpenArticleId);
        }

    }, [currentOpenArticleId]);


    useEffect(() => {
        async function getAndSetArticle(articleId) {
            const fileDetails = await FileService.getFilesDetails([articleId]);

            if (fileDetails.find((file) => file.id === articleId)) {
                setCurrentOpenArticleId(articleId);
                setSelectedArticles(fileDetails);
            } else {
                new ClientError(
                    new Error('Article was not found or you do not have permission to access it.')
                ).toast();
            }
        }

        const getArticleIdFromUrl = () => {
            const params = new URLSearchParams(window.location.search);
            return params.get('id');
        };

        const urlArticleId = getArticleIdFromUrl();

        if (urlArticleId) {
            getAndSetArticle(urlArticleId);
        }
    }, [setCurrentOpenArticleId, setSelectedArticles]);


    useEffect(() => {
        const checkReadingListStatus = async (urlArticleId) => {
            try {
                const result = await UserFileDataService.checkReadingListStatus(urlArticleId);
                setIsInReadingList(result);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        if (currentOpenArticleId !== '') {
            checkReadingListStatus(currentOpenArticleId);
        }
    }, [currentOpenArticleId])

    // We will have +-5 levels zoom in/out
    // const zoomChange = (val: number) => {
    //     const newZoomLevel = zoomLevel + val;
    //     const boundedZoomLevel = Math.max(-3, Math.min(newZoomLevel, 2));
    //     setZoomLevel(boundedZoomLevel);
    // }

    // const fullScreen = () => {
    //     setFullScreenMode(true);
    // }

    // const toggleReadingList = async (fileId: string) => {
    //     const originalIsInReadingList = isInReadingList;
    //     try {
    //         setIsInReadingList(!isInReadingList);
    //         await UserFileDataService.toggleReadingList(fileId);
    //     } catch (error) {
    //         setIsInReadingList(originalIsInReadingList);
    //         new ClientError(error).toast();
    //     }
    // }

    const contextOnSelection = () => {
        clearHighlights(HighlightType.Quotation);
        highlightSentences({
            [currentOpenArticleId]: selectionSentenceIndexes
        }, HighlightType.Quotation, true)
        setHasCompleteSelection(false);
        setQuoteSentenceIndexes(selectionSentenceIndexes);
    }

    // const clearAllHighlights = () => {
    //     clearHighlights(HighlightType.Quotation);
    //     clearHighlights(HighlightType.TaskQA);
    //     clearHighlights(HighlightType.TaskReading);
    //     setSelectedMessageId('');
    // }

    const highlightListOnClick = () => {
        if (toolWindow !== 'highlight') {
            setToolWindow('highlight');
            setToolWindowOpenStatus(true);
        } else {
            setToolWindow('');
            setToolWindowOpenStatus(false);
        }
    }

    const termsOnClick = () => {
        if (toolWindow !== 'terms') {
            setToolWindow('terms');
            setToolWindowOpenStatus(true);
        } else {
            setToolWindow('');
            setToolWindowOpenStatus(false);
        }
    }

    const readSessionsOnClick = () => {
        if (toolWindow !== 'readSessions') {
            setToolWindow('readSessions');
            setToolWindowOpenStatus(true);
        } else {
            setToolWindow('');
            setToolWindowOpenStatus(false);
        }
    }

    const libraryOnClick = () => {
        if (toolWindow !== 'library') {
            setToolWindow('library');
            setToolWindowOpenStatus(true);
        } else {
            setToolWindow('');
            setToolWindowOpenStatus(false);
        }
    }

    return (
        <>
            <Flex w={'100%'} h={'100%'} flexDir={'row'} className="main-bg">
                <ReaderSidebar sideBarOpenStatus={sideBarOpenStatus} setSideBarOpenStatus={setSideBarOpenStatus} />
                <Flex flexDir={'column'} w={'full'} h={'full'} bg={'white'}>
                    <Flex flexDir={'row'} px={5} py={5} alignItems={'center'} justifyContent={'space-between'}>
                        <ArticleTitleSelectionWrapper />
                        <Box>
                            <Button variant={'ghost'} mr={2} leftIcon={<LuClipboardSignature />} onClick={highlightListOnClick} bg={toolWindow === 'highlight' ? 'gray.100' : null}>Highlight List</Button>
                            <Button variant={'ghost'} mr={2} leftIcon={<LuClipboardSignature />} onClick={termsOnClick} bg={toolWindow === 'terms' ? 'gray.100' : null}>Terms</Button>
                            <Button variant={'ghost'} mr={2} leftIcon={<LuClipboardSignature />} onClick={readSessionsOnClick} bg={toolWindow === 'readSessions' ? 'gray.100' : null}>Read Sessions</Button>
                            <Button variant={'ghost'} mr={2} leftIcon={<LuClipboardSignature />} onClick={libraryOnClick} bg={toolWindow === 'library' ? 'gray.100' : null}>Library</Button>
                            <LangSelect />
                            {isAuthenticated && user && (
                                <UserMenu user={user} />
                            )}
                        </Box>
                    </Flex>
                    <Box flex={'1 auto'} p={5} bg={'gray.100'}>
                        <Flex flexDir={'row'} bg={'white'} w={'full'} h={'full'} border={'1px solid #ccc'} borderRadius={'15px'} overflow={'hidden'}>
                            <Flex flexDir={'column'} flex={'auto'} boxShadow={'md'}>
                                <Tabs>
                                    <TabList>
                                        <Tab>PDF File</Tab>
                                        <Tab>Summary</Tab>
                                        <Tab>Key Sentences</Tab>
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel>
                                            <p>PDF</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Summary</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Key Sentences</p>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Flex>
                            <Box w={'35%'} minW={'650px'} pos={'relative'} p={5}>
                                Reading Session 1
                                {toolWindowOpenStatus &&
                                    <Box pos={'absolute'} top={0} right={0} w={'full'} h={'500px'} bg={'white'} boxShadow={'md'} overflow={'auto'}>
                                        <Box pos={'absolute'} zIndex={2} top={'5px'} right={'5px'}>
                                            <IconButton icon={<CloseIcon />} aria-label="close" variant={'ghost'} size={'sm'} onClick={() => setToolWindowOpenStatus(false)} />
                                        </Box>
                                        {toolWindow === 'highlight' && <HighlightSentencesWrapper />}
                                        {toolWindow === 'terms' && <TermsWrapper />}
                                        {toolWindow === 'readSessions' && 
                                            <ReadSessionsWrapper 
                                                sessionDrawerOpenStatus = {sessionDrawerOpenStatus}
                                                setSessionDrawerOpenStatus = {setSessionDrawerOpenStatus}
                                                currentConversation = {null}
                                                filteredConversations = {null}
                                                addNewConversation = {null}
                                                conversationOnClick = {null}
                                                deleteConversation = {null}
                                                downloadTranscript = {null}
                                                showAllQA = {null}
                                                setShowAllQA = {null}
                                            />
                                        }
                                        {toolWindow === 'library' && <LibraryWrapper />}
                                    </Box>
                                }
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Flex >
            <Modal onClose={() => setFullScreenMode(false)} size={'full'} isOpen={fullScreenMode} scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent overflow={'hidden'} pt={12} >
                    <ModalCloseButton zIndex={99} />
                    <ModalBody transform={'scale(1.2)'} mt={12} >
                        {selectedArticles.length > 0 && <Box dangerouslySetInnerHTML={{ __html: htmlContent }} height="100%" width="100%" />}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {hasCompleteSelection && selectionContext && floatingDivPosition
                && containsFeatures(currentlySelectedArticle, ArticleFeature.QAHighlight)
                && (
                    <Box className="floating-div"
                        style={{
                            position: 'absolute',
                            top: floatingDivPosition.top + window.scrollY - 30, // Adjust the top position as needed
                            left: floatingDivPosition.left,
                            backgroundColor: 'yellow.400',
                            zIndex: 9999,
                        }}>
                        <Button onClick={contextOnSelection}
                            colorScheme='yellow'
                            size='xs'
                            leftIcon={<FaQuoteLeft fontSize={8} />}>
                            {t('quotation-label')}
                        </Button>
                    </Box>
                )
            }
        </>
    )
}

export default ReaderComponent;