import React from 'react';
import {
    Box,
    Text,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Button,
    useDisclosure,
    Flex
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { Paper } from '../../types/Writer/Paper';
import { formatDate } from '../../utils/dateTimeConversion';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';

interface PaperCardProps {
    paper: Paper;
    viewPaper: () => void;
    continueEditing: () => void;
    discardPaper: () => void;
}

const PaperCard: React.FC<PaperCardProps> = ({ paper, viewPaper, continueEditing, discardPaper }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box
            w={'200px'}
            h={'260px'}
            border={'1px solid #ccc'}
            bg={'white'}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            display={'flex'}
            flexDir={'column'}
            justifyContent={'flex-end'}
            boxShadow="sm"
            bgImage="url('/paper_preview.png')" // Path to the image in the public folder
            bgSize="cover" // Cover the whole box
            bgPosition="center" // Center the image
            bgRepeat="no-repeat" // Prevent the image from repeating
            onDoubleClick={continueEditing}
            cursor={'pointer'}
            transition={'all 0.5s ease'}
            _hover={{ boxShadow: 'md', borderColor: 'gray' }}
        >
            <Box bg={'white'} borderTop={'1px solid #ccc'} p={4}>
                <Text fontSize="xl" fontWeight="bold">
                    {paper.name}
                </Text>
                {/* <Text fontSize="sm">
                    @{paper.authors}
                </Text> */}
                <Flex flexDir='row' justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize={10} as={'b'}>{formatDate(paper.createdAt)}</Text>
                    <Stack direction="row" justifyContent="space-between" bg={'white'} alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<FiMoreVertical />}
                                variant="ghost"
                                aria-label="Options"
                                size={'xs'}
                            />
                            <MenuList>
                                <MenuItem icon={<EditIcon />} onClick={continueEditing}>
                                    Edit
                                </MenuItem>
                                <MenuItem icon={<ViewIcon />} onClick={viewPaper}>
                                    View
                                </MenuItem>
                                <MenuItem icon={<DeleteIcon />} onClick={onOpen}>
                                    Discard
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Stack>
                </Flex>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Discard this paper</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Do you really want to discard this paper?
                        </ModalBody>

                        <ModalFooter>
                            <Button size={'sm'} variant='ghost' mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button size={'sm'} colorScheme='red' onClick={discardPaper}>
                                Discard
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Box>
    );
};

export default PaperCard;
