import React, { useState } from 'react';
import { Box, Button, Center, Flex, IconButton, Text, VStack } from '@chakra-ui/react';
import { PaperOutlineNode } from '../../types/Writer/OutlineNode';
import { MdOutlineSmsFailed } from 'react-icons/md';
import { AddIcon } from '@chakra-ui/icons';
import Toolbox from './Toolbox';

interface DocumentRendererProps {
    data: PaperOutlineNode[];
}

const ParagraphNode: React.FC<{ node: PaperOutlineNode }> = ({ node }) => {
    const [isHovered, setIsHovered] = useState(false);
    const onParagraphMouseOver = () => {
        setIsHovered(true);
    };
    const onParagraphMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Flex
            flexDir={'row'}
            w={'full'}
            onMouseOver={onParagraphMouseOver}
            onMouseLeave={onParagraphMouseLeave}
        >
            <Center
                bg={'#f9f9f9'}
                w={'40px'}
                color={isHovered ? 'black' : 'white'}
                transition={'all ease 0.5s'}
            >
                {node.id}
            </Center>
            <Flex flexDir={'column'} w={'full'} p={2} py={5}>
                <Box>
                    {/* <MdDragIndicator /> */}
                    <Text key={node.id} mb={2}>
                        {node.content} {isHovered && <IconButton contentEditable={false} size={'xs'} aria-label='Search database' icon={<MdOutlineSmsFailed />} />}
                    </Text>
                </Box>
                { isHovered && 
                    <Flex flexDir={'row'} w={'full'} transition={'all ease 0.5s'} contentEditable={false}>
                        <Flex flexDir={'row'} mr={5}>
                            <Button size={'xs'} mr={2} >Rephrase</Button>
                            <Button size={'xs'} mr={2} >Suggestion</Button>
                            <Button size={'xs'} >Reorganize</Button>
                        </Flex>
                        <Button size={'xs'} leftIcon={<AddIcon />}>Add New Paragraph Below</Button>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
};

const renderNode = (node: PaperOutlineNode): React.ReactNode => {
    if (node.type === 'section' && node.name) {
        return (
            <React.Fragment key={node.id}>
                <Text as='b' fontSize={'lg'} ml={12} mb={2}>{node.name}</Text>
                {node.children && node.children.map(child => renderNode(child))}
            </React.Fragment>
        );
    }
    if (node.type === 'paragraph' && node.content) {
        return <ParagraphNode key={node.id} node={node} />;
    }
    return null;
};

const PaperFlaternContent: React.FC<DocumentRendererProps> = ({ data }) => {
    const [_styledContent, setStyledContent] = useState(data);

    const applyStyle = (style: string, nodeId: number) => {
        setStyledContent((prevData) =>
          prevData.map((node) => {
            if (node.id === nodeId) {
              let updatedContent = node.content;
              if (style === "bold") updatedContent = `<b>${node.content}</b>`;
              if (style === "italic") updatedContent = `<i>${node.content}</i>`;
              if (style === "underline") updatedContent = `<u>${node.content}</u>`;
              if (style === "list") updatedContent = `• ${node.content}`;
    
              return { ...node, content: updatedContent };
            }
            return node;
          })
        );
      };

    return (
        <VStack borderRadius={5} align="start" contentEditable outline={'none'} gap={0} pb={8} minW={'70%'} maxW={'900px'} h={'full'}>
            <Toolbox 
                onApplyStyle={(style) => applyStyle(style, 0)} 
            />
            {data.length > 0 && data.map((node) => renderNode(node))}
        </VStack>
    );
};

export default PaperFlaternContent;
