import { Box, Flex, Image, Tooltip, useBreakpointValue, Button, Icon, Link as ChakraLink, Center, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Search2Icon } from "@chakra-ui/icons";
import { GiArchiveResearch } from "react-icons/gi";
import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { LuPenTool } from "react-icons/lu";
import { IoIosHelpCircle, IoIosInformationCircle } from "react-icons/io";
import { useRecoilState } from "recoil";
import { leftDrawerOpenStatus } from "../../atoms/rootAtom";

const SideNav = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });
    const isCurrentPathMyDrive = location.pathname === "/my-library";
    const navigate = useNavigate();
    const [_drawerOpenStatus, setDrawerOpenStatus] = useRecoilState(leftDrawerOpenStatus);


    const menus = [
        {
            link: '/search',
            label: 'mini-nav-tasksearch',
            description: 'mini-nav-tasksearch-description',
            mobileIcon: Search2Icon
        },
        {
            link: '/reader',
            label: 'mini-nav-taskrqa',
            description: 'mini-nav-taskrqa-description',
            mobileIcon: GiArchiveResearch
        },
        {
            link: '/editor',
            label: 'mini-nav-editor',
            description: 'mini-nav-editor-description',
            mobileIcon: LuPenTool
        }
        // {
        //     link: '/reader/notes',
        //     label: 'mini-nav-notes',
        //     description: 'mini-nav-notes-description',
        //     mobileIcon: GiArchiveResearch
        // }
    ]

    const matchingTabIndex = menus.findIndex(tab => matchPath(tab.link, location.pathname));

    const logoOnClick = () => {
        navigate('/editor')
    }

    return (
        <>
            <Flex flexDir={'column'} minW={isMobile ? '60px' : '280px'} justifyContent={'space-between'} h={'full'} p={5} borderRight={'2px solid white'}>
                <Flex flexDir={'column'} h={'full'}>
                    {!isMobile &&
                        <Center pt={5} onClick={logoOnClick}>
                            <Image
                                w={'150px'}
                                objectFit='cover'
                                src='/doenba-logo-blue.png'
                                alt='Doenba'
                            />
                        </Center>
                    }
                    {isMobile &&
                        <Center pt={5} onClick={logoOnClick}>
                            <Image
                                w={'35px'}
                                objectFit='cover'
                                src='/doenba-ico-blue.png'
                                alt='Doenba'
                            />
                        </Center>
                    }
                    <Flex flexDir={'column'} mt={12} justifyContent={'space-between'} h={'full'}>
                        <Box>
                            {isCurrentPathMyDrive &&
                                <Flex flexDir='column'>
                                    {menus.map((menu, index) => (
                                        <Button mb={2} colorScheme="blue" key={menu.link} variant={matchingTabIndex === index ? 'solid' : 'ghost'}>
                                            <ChakraLink as={RouterLink} to={menu.link}>
                                                <Tooltip label={t(menu.description)} placement='bottom'>
                                                    {t(menu.label)}
                                                </Tooltip>
                                            </ChakraLink>
                                        </Button>
                                    ))}
                                </Flex>
                            }
                            {!isCurrentPathMyDrive &&
                                <Flex flexDir='column'>
                                    {!isMobile && menus.map((menu, index) => (
                                        <Button mb={2} size={'lg'} colorScheme={matchingTabIndex === index ? 'brand' : 'none'} variant={matchingTabIndex === index ? 'solid' : 'ghost'} key={menu.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                                            <ChakraLink as={RouterLink} to={menu.link}>
                                                <Tooltip label={t(menu.description)} placement='bottom'>
                                                    {t(menu.label)}
                                                </Tooltip>
                                            </ChakraLink>
                                        </Button>
                                    ))}
                                    {isMobile && menus.map((menu, index) => (
                                        <Button mb={2} colorScheme="blue" variant={matchingTabIndex === index ? 'solid' : 'ghost'} key={menu.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                                            <ChakraLink as={RouterLink} to={menu.link} pb={0}>
                                                <Tooltip label={t(menu.description)} placement='bottom'>
                                                    <span>
                                                        <Icon as={menu.mobileIcon} />
                                                    </span>
                                                </Tooltip>
                                            </ChakraLink>
                                        </Button>
                                    ))}
                                </Flex>
                            }
                        </Box>
                        <VStack py={12}>
                            {!isMobile &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">Tutorials</ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">Helps</ChakraLink>
                                </>
                            }
                            {isMobile &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosInformationCircle color="#0057ec" />
                                    </ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosHelpCircle color="#0057ec" />
                                    </ChakraLink>
                                </>
                            }
                        </VStack>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default SideNav;